import { forwardRef, useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { CSS_CLASSES } from "../../../constants/css-classes";
import { VOICE_FILE_PLAY_SPEED, VOICE_GENDER, VOICE_FILE_PLAY_SPEED_RATE } from "../../../constants/voice-file";
import { REGEX } from "../../../config/config";

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrSnackBar from "../../common/dialog/pmivr-snackbar";
import PmivrLabel from "../../common/label/pmivr-label";
import AudioPlayer from "../../common/audio-player/audio-player";

import AudioUtil from "../../../util/audio.util";

import FlowService from "../../../services/flow.service";
import AudioService from "../../../services/audio.service";

/**
 * Text to speech option in the diagram
 */
const TextToSpeechOption = forwardRef((props, ref) => {
  const snackbarRef = useRef();
  /**
   * uploadTtsBtnDisable : flag to disable and enable tts upload button
   * showSpeedError : flag to show speed error message
   */
  const [uiState, setUiState] = useState({ uploadTtsBtnDisable: true, showSpeedError: false });
  const [showTtsTextWarning, setShowTtsTextWarning] = useState(false);
  const [voiceFileInfo, setVoiceFileInfo] = useState({ "file": null, "ttsText": "", "selectedGender": "", "playSpeed": "" });

  const { selectedLanguage, onChange, pathInfo } = props;

  useEffect(() => {
    const init = () => {
      const voiceFileInformation = props.voiceFileInfo[selectedLanguage];
      setVoiceFileInfo({
        "file": null, "ttsText": !voiceFileInfo.ttsText ? (voiceFileInformation?.ttsText || "") : voiceFileInfo.ttsText,
        "selectedGender": !voiceFileInfo.selectedGender ? (voiceFileInformation?.gender || VOICE_GENDER.FEMALE) : voiceFileInfo.selectedGender,
        "playSpeed": !voiceFileInfo.playSpeed ? (voiceFileInformation?.playSpeed ? handlePlaySpeed(voiceFileInformation) :
          VOICE_FILE_PLAY_SPEED_RATE.NORMAL) : voiceFileInfo.playSpeed
      });
    }
    init();
  }, [selectedLanguage, props.voiceFileInfo]);

  /**
   * Handles the play speed of the tts voice file
   * Gives back support to already saved tts files with speed
   * @param {{playSpeed,gender,filePath,file,ttsText}} voiceFileInformation 
   * @returns speed of the tts voice file
   */
  const handlePlaySpeed = (voiceFileInformation) => {
    switch (voiceFileInformation?.playSpeed) {
      case VOICE_FILE_PLAY_SPEED.SLOW:
        return VOICE_FILE_PLAY_SPEED_RATE.SLOW;
      case VOICE_FILE_PLAY_SPEED.NORMAL:
        return VOICE_FILE_PLAY_SPEED_RATE.NORMAL;
      case VOICE_FILE_PLAY_SPEED.FAST:
        return VOICE_FILE_PLAY_SPEED_RATE.FAST;
      default:
        return voiceFileInformation?.playSpeed;
    }
  }

  /**
   * TTS gender selection 
   * @param {Event} event 
   */
  const genderRadioBtnHandler = (event) => {
    // enable the upload button on gender change
    setUiState({ ...uiState, uploadTtsBtnDisable: false });
    // update the gender value in voice file info
    setVoiceFileInfo({ ...voiceFileInfo, selectedGender: event?.target?.value });
  }

  /**
   * TTS voice speed
   * @param {Event} event 
   */
  const setVoiceSpeed = (event) => {
    // enable the upload button on speed change
    setUiState({ ...uiState, showSpeedError: false, uploadTtsBtnDisable: false });
    // update the playSpeed of voice file in voice file info
    setVoiceFileInfo({ ...voiceFileInfo, playSpeed: event?.target?.value });
  }

  /**
   * Uploads the tts file
   */
  const uploadTts = async () => {
    try {
      // check if entered the valid speed or not
      if (voiceFileInfo?.playSpeed < 10 || voiceFileInfo.playSpeed > 100) {
        setUiState({ ...uiState, showSpeedError: true });
        return;
      }
      // get the basic flow info
      const basicFlowInfo = FlowService.getBasicFlowInfo();
      // basic flowInfo for tts
      const flowInfo = {
        businessCode: pathInfo?.businessCode || basicFlowInfo?.businessCode,
        language: selectedLanguage, flowName: pathInfo?.dirName || basicFlowInfo?.flowName
      };
      // necessary fields in case of tts
      flowInfo.text = voiceFileInfo?.ttsText;
      flowInfo.gender = voiceFileInfo?.selectedGender;
      // speed of the voice file generated from tts text
      flowInfo.speedRate = voiceFileInfo?.playSpeed;
      // get the voice type info from gender and selected language
      const ttsVoiceInfo = AudioUtil.getTtsVoiceId(selectedLanguage, voiceFileInfo?.selectedGender);
      flowInfo.voiceId = ttsVoiceInfo.voiceId;
      flowInfo.languageCode = ttsVoiceInfo.languageCode;
      const response = await AudioService.tts(flowInfo);
      if (response) {
        onChange({
          filePath: response?.data?.metaInfo?.location, isUploadedOnGit: response?.data?.metaInfo?.isUploadedOnGit,
          gender: voiceFileInfo.selectedGender, playSpeed: voiceFileInfo.playSpeed, ttsText: voiceFileInfo.ttsText
        });
        snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
        setUiState({ ...uiState, uploadTtsBtnDisable: true });
      }
    } catch (err) {
      setUiState({ ...uiState, uploadTtsBtnDisable: false });
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
      }
    }
  }

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="d-flex justify-content-between">
        <div className="pmivr-title pt-2">Create custom Audio</div>
        <AudioPlayer filePath={props.voiceFileInfo[selectedLanguage]?.filePath}
          cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
          isUploadedOnGit={props.voiceFileInfo[selectedLanguage]?.isUploadedOnGit}></AudioPlayer>
      </div>
      {/* text to speech */}
      <div className="px-0">
        <div className="form-group mb-2 pmivr-relative">
          <PmivrLabel label="Type Message" tooltip={TOOLTIP.INFO.TTS} />
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS}>
            <textarea id="greetingAudioMessage" className="form-control pmivr-input" value={voiceFileInfo.ttsText}
              onChange={(event) => {
                const inputValue = event.target.value;
                const ttsRegex = REGEX.TTS_TEXT;
                // If the input value contains special characters(&,*,'',$), disable the upload button and show tts warning  
                if (!ttsRegex.test(inputValue)) {
                  setShowTtsTextWarning(true);
                  setUiState({ ...uiState, uploadTtsBtnDisable: true });
                } else {
                  setShowTtsTextWarning(false);
                  setUiState({ ...uiState, uploadTtsBtnDisable: false });
                }
                const tempInfo = voiceFileInfo;
                tempInfo.ttsText = inputValue;
                setVoiceFileInfo(tempInfo);
              }}
            />
            {showTtsTextWarning && <div className='field-error'>{MESSAGES.TTS_TEXT_WARNING}</div>}
          </PmivrOverlayTrigger>
        </div>
        <div className="row px-2">
          {/* voice type gender - male/female */}
          <div className="col-sm-7 p-1">
            <label>Gender: </label>
            <Form className="mt-2">
              <Form.Check
                inline
                className="pmivr-check-radio"
                label="male"
                type={"radio"}
                value="male"
                checked={voiceFileInfo.selectedGender === VOICE_GENDER.MALE}
                onChange={(e) => genderRadioBtnHandler(e)}
              />
              <Form.Check
                inline
                className="pmivr-check-radio"
                label="female"
                type={"radio"}
                value="female"
                checked={voiceFileInfo.selectedGender === VOICE_GENDER.FEMALE}
                onChange={(e) => genderRadioBtnHandler(e)}
              />
            </Form>
          </div>
          <div className="col-sm-5 p-1">
            <div className="form-group">
              <PmivrLabel label="Speed" tooltip={TOOLTIP.INPUT.TTS_SPEED} cssClass={`mt-0 mb-1`} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS_SPEED}>
                <input
                  type="number"
                  className="flow-control pmivr-input"
                  aria-label="Default input example"
                  value={voiceFileInfo.playSpeed}
                  onChange={(e) => setVoiceSpeed(e)}
                  min={10}
                  max={100}
                />
              </PmivrOverlayTrigger>
            </div>
          </div>
          {uiState?.showSpeedError && <div className='field-error'>{MESSAGES.TTS_SPEED_WARNING}</div>}
          <button
            className="pmivr-btn-secondary mt-3 p-3"
            disabled={uiState.uploadTtsBtnDisable || !voiceFileInfo.ttsText}
            onClick={() => uploadTts()}>Generate Speech File</button>
        </div>
      </div>
    </>
  );
});

TextToSpeechOption.propTypes = {
  voiceFileInfo: PropTypes.object,
  // retirn s the selected language name
  selectedLanguage: PropTypes.string,
  // onChange function to update tts values in prompts
  onChange: PropTypes.func,
  pathInfo: PropTypes.object
}

export default TextToSpeechOption;
