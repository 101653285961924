export const DNID_NUMBER_STATUS = {
    AVAILABLE: "AVAILABLE",
    OCCUPIED: "OCCUPIED"
};

// regex for checking valid dnid number
export const DNID_NUMBER_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

//  Extension status for checking whether the extension valid or not while configuring the DNID
export const EXT_STATUS = {
    VALID: "valid",
    INVALID: "invalid"
};

// Constants for updating state
export const UPDATE_STATE = {
    SET_DNID_INFO: "setDnidInfo",
    SET_QUESTIONNAIRE: "setQuestionnaire"
};