import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";

import { updateSystemVariablesList } from "../../../redux/actions/config.action";

import PmivrSnackbar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import PmivrTooltip from "../../../components/common/tooltip/pmivr-tooltip";

import ClientService from "../../../services/client.service";
import VariableService from '../../../services/variable.service';
import ConfigService from "../../../services/config.service";
import { TASK_ICONS } from "../../../constants/css-classes";
import { EXT_STATUS } from "../../../constants/dnid";

/**
 * Dnid values modal to configure variables based dnid 
 * @param {Object} props props passed to this component
 * @param {{ dnidRowInfo, updateDnidRowValues, dnidNumbersList, numberPool, closeAction, clientFlowExists, enableExt, dnidIndex, languages }} props passed to this component
 * @returns {React.Component} Html code to render 
 */
const DnidVariablesModal = (props) => {
    const { dnidRowInfo, updateDnidRowValues, dnidNumbersList, numberPool, closeAction, clientFlowExists, enableExt,
        dnidIndex, languages = []
    } = props;
    const snackbarRef = useRef();
    const dispatch = useDispatch();
    /**
     * disableForm : To disable the form if edit not pressed
     * message: Any error while checking extension and text error to display and extStatus for having status for showing 
     * green tick and red cross
     * lext : Extension number for the flow
     */
    const [uiState, setUiState] = useState({
        disableForm: false,
        message: { text: '', isError: false, extStatus: "check" }, lext: dnidRowInfo?.lext
    });
    // variables that we save against particular dnid
    const [dnidVariables, setDnidVariables] = useState([]);
    const [selectedNumberInfo, setSelectedNumberInfo] = useState();
    // list of variables names
    const [variablesName, setVariablesName] = useState([]);
    // transferChannelCode : For particular flow user can select a different transfer number and greater than 6
    const [transferChannelCode, setTransferChannelCode] = useState(dnidRowInfo?.transferChannelCode || "");
    // suggested possible values for the extension number
    const [lextSuggestions, setLextSuggestions] = useState([]);
    // transfer number configuration for different languages
    const [transferNumberConfig, setTransferNumberConfig] = useState([]);

    useEffect(() => {
        if (dnidRowInfo?.filledVars) {
            setDnidVariables(dnidRowInfo.filledVars);
        }
        const numberInfo = numberPool.find((number) => number.phoneNumber === dnidRowInfo?.dnid);
        setSelectedNumberInfo(numberInfo);
        setExtensionValueSuggestions();
        transformTransferNumberConfig();
    }, [dnidRowInfo]);

    useEffect(() => {
        setAllVariables();
    }, []);

    /**
     * tranforms the transfer number object in dnid row info into a standardized format
     */
    const transformTransferNumberConfig = async () => {
        // if value is string, then it means that the numbers are configured not as per language
        // so, need to update the numbers as per language
        if (typeof dnidRowInfo.transferNumber === 'string') {
            const number = languages?.map((lang) => ({
                language: lang,
                transferNumber: dnidRowInfo.transferNumber,
                transferNumberExtension: ''
            }));
            setTransferNumberConfig(number);
        } else {
            const transformedConfig = Object?.keys(dnidRowInfo?.transferNumber)?.map(lang => ({
                language: lang,
                transferNumber: dnidRowInfo.transferNumber[lang] || '',
                transferNumberExtension: dnidRowInfo?.transferNumberExtension[lang] || ''
            }));
            setTransferNumberConfig(transformedConfig);
        }
    }

    /**
     * Suggests extension values for the current DNID based on its valid range. 
     * It calculates possible extensions within the range and sets the top 3 as suggestions.
     */
    const setExtensionValueSuggestions = async () => {
        try {
            const numberConfig = numberPool.find((number) => number.phoneNumber === dnidRowInfo?.dnid);
            const rangeStart = numberConfig?.rangeStart;
            const rangeEnd = numberConfig?.rangeEnd;
            if (!rangeStart && !rangeEnd) {
                setLextSuggestions([]);
            }
            else {
                // Collect configured lext values for the current dnid
                const configuredLext = dnidNumbersList.filter((number) => number.dnid === dnidRowInfo?.dnid)
                    .map((number) => number.lext)
                    .filter((lext) => lext);
                // Convert array to comma-separated string
                const lextValues = configuredLext.join(',');
                const suggestedExtensionValues = await ClientService.suggestExtensionValues({
                    lextValues, rangeStart, rangeEnd, dnid: dnidRowInfo?.dnid
                });
                setLextSuggestions(suggestedExtensionValues.data);
            }
        }
        catch (err) {
            return
        }
    }

    /**
     * Get variables and set it in variables state.
     */
    const setAllVariables = async () => {
        const systemVariables = await ConfigService.getSysVariable();
        dispatch(updateSystemVariablesList({ systemVariables }));
        const variablesInfo = await VariableService.getVariables();
        // names of all the variables
        const allVariablesNames = variablesInfo.map((varInfo) => varInfo.name);

        // populating the variables in state after getting the variable names.
        // Using timeout as getting all variable names is consuming time.
        setTimeout(() => {
            setVariablesName(allVariablesNames);
        }, 1000);
    }

    /**
     * Adds the new row inside the dialog form of dnid variable configuration
     */
    const handleAddRow = () => {
        if (dnidVariables.length < variablesName?.length) {
            setDnidVariables([{ variable: '', variableType: '', propertyName: '', value: '', collection: "" }, ...dnidVariables]);
        }
    };

    /**
     * Removes the row in the dialog form of configuring variables based dnid
     * @param {number} index index of the row to be deleted
     */
    const handleRemoveRow = (index) => {
        setDnidVariables(dnidVariables.filter((_, i) => i !== index));
    };

    /**
     * Handles the change in variables configuration for dnid
     * @param {number} index index of the row to update
     * @param {*} field field that needs to be updated
     * @param {*} value value with which we update the value for field
     */
    const handleChange = (index, field, value) => {
        // Create a deep copy of the dnidVariables array
        const newDnidRows = dnidVariables.map((row, i) => {
            if (i === index) {
                return {
                    ...row,
                    [field]: value
                };
            }
            return row;
        });
        setDnidVariables(newDnidRows);
    };

    /**
     * Adds the new row inside the dialog form of transfer number configuration
     */
    const handleTransferNumberAddRow = () => {
        setTransferNumberConfig([{ language: '', transferNumber: '', transferNumberExtension: '' }, ...transferNumberConfig]);
    };

    /**
     * Removes the row in the dialog form of configuring transfer number
     * @param {number} index index of the row to be deleted
     */
    const handleTransferNumberRemoveRow = (index) => {
        setTransferNumberConfig(transferNumberConfig.filter((_, i) => i !== index));
    };

    /**
     * Handles the change in transfer number configuration
     * @param {number} index index of the row to update
     * @param {*} field field that needs to be updated
     * @param {*} value value with which we update the value for field
     */
    const handleTransferNumberChange = (index, field, value) => {
        // Create a deep copy of the transfer number config array
        const newTransferNumberRows = transferNumberConfig.map((row, i) => {
            if (i === index) {
                return {
                    ...row,
                    [field]: value
                };
            }
            return row;
        });
        setTransferNumberConfig(newTransferNumberRows);
    };


    /**
     * Save the dnid configuration values in thi dnid rows
     * @param {Object} e 
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (dnidVariables.length) {
            const variableNames = dnidVariables?.map(item => item.variable);
            const duplicateVariables = variableNames.filter((item, index) => variableNames.indexOf(item) !== index);
            if (duplicateVariables.length > 0) {
                setUiState({
                    ...uiState, disableForm: false,
                    message: { text: MESSAGES.DNID_INFO.SAME_VARIABLE_USED, isError: true }
                });
                return;
            }
        }

        const isValidExt = await checkExtValidity();
        if (isValidExt) {
            setUiState({ ...uiState, disableForm: true });
            try {
                const transferNumber = {}, transferNumberExtension = {};
                // extract transfer number and transfer number extension values by language
                transferNumberConfig?.forEach(item => {
                    transferNumber[item.language] = item.transferNumber;
                    transferNumberExtension[item.language] = item.transferNumberExtension;
                });
                const newDnidRowInfo = {
                    dnidRowValues: dnidVariables, newDnidValues: dnidRowInfo, lext: uiState.lext, dnidIndex,
                    transferChannelCode, transferNumber, transferNumberExtension
                };
                updateDnidRowValues(newDnidRowInfo);
                setUiState({ disableForm: true, message: { text: MESSAGES.DNID_INFO.SAVED_SUCCESSFULLY, isError: false } });
                closeAction();
            } catch (err) {
                setUiState({ disableForm: false, message: { text: MESSAGES.SOMETHING_WENT_WRONG, isError: true } });
            }
        }
    };

    /**
     * Checks if the form is valid , has some errors to disable the submit button
     * @returns 
     */
    const isFormValid = () => {
        const dnidVariablesCheck = dnidVariables.every(row => row.variable && row.variableType && row.value &&
            (row.variableType === 'single' || row.propertyName));
        const transferNumberConfigsCheck = transferNumberConfig.every(row => row.language && row.transferNumber);
        return dnidVariablesCheck && transferNumberConfigsCheck;
    };

    /**
     * Check if extension is valid or not
     * @returns {boolean} returns true false based on extension validity, extension nmber is valid or not
     */
    async function checkExtValidity() {
        try {
            if ((!clientFlowExists && uiState?.lext) || (clientFlowExists && enableExt)) {
                setUiState({ ...uiState, message: { text: "", isError: false } });
                const foundExtension = dnidNumbersList.find((dnidRow, index) => {
                    if (index !== dnidIndex) {
                        return (dnidRow.dnid === dnidRowInfo?.dnid) && (dnidRow.lext === uiState.lext) && (index !== dnidRowInfo.index);
                    }
                });

                if (foundExtension) {
                    setUiState({
                        ...uiState, message: {
                            text: MESSAGES.DNID_INFO.ALREADY_CONFIGURED, isError: true,
                            extStatus: "invalid"
                        }
                    });
                    return false;
                }
                // check for validation if they lie within range 
                if (!typeof (Number(uiState.lext)) === 'number' ||
                    !(Number(uiState.lext) >= selectedNumberInfo?.rangeStart && Number(uiState.lext) <= selectedNumberInfo?.rangeEnd)
                ) {
                    setUiState({
                        ...uiState, message: {
                            text: MESSAGES.ENTER_VALID_EXTENSION + ` ${selectedNumberInfo?.rangeStart} to ${selectedNumberInfo?.rangeEnd}`,
                            isError: true, extStatus: "invalid"
                        }
                    });
                    return false;
                } else {
                    // verify from DB, check the existing ext value here
                    const dnidConfigResponse = await ClientService.getDnidConfig({ dnid: dnidRowInfo?.dnid, lext: uiState.lext });
                    const existingExt = (dnidConfigResponse?.length) ? dnidConfigResponse[0] : null;
                    if (existingExt && Object.keys(existingExt).length) {
                        setUiState({
                            ...uiState, message: {
                                text: MESSAGES.EXTENSION_ALREADY_EXISTS, isError: true, extStatus: "invalid"
                            }
                        });
                        return false;
                    } else {
                        setUiState({ ...uiState, message: { text: "", isError: false, extStatus: "valid" } });
                        return true;
                    }
                }
            } else {
                setUiState({ ...uiState, message: { text: "", isError: false, extStatus: "valid" } });
                return true;
            }
        } catch (err) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERROR_EXTENSION);
                setUiState({ ...uiState, message: { text: MESSAGES.ERROR_EXTENSION, isError: true, extStatus: "invalid" } });
                return false;
            }
        }
    };

    /**
     * handle extension 
     * @param {string} extValue value of change in extension
     */
    const handleExt = (extValue) => {
        setUiState({ ...uiState, lext: extValue, message: { text: "", isError: false, extStatus: "check" } });
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <div className="pmivr-number-input">
                <form onSubmit={handleSubmit}>
                    <div className="pmivr-container">
                        <div className="wrapper dnid-variables-modal">
                            <div className="row mb-2 remove-arrows">
                                <div className={`${dnidRowInfo?.showExt ? "col" : "col-md-4"}`}>
                                    <PmivrLabel label="DNID" tooltip={TOOLTIP.INFO.DNID} />
                                    <input type="text" name="dnid" value={dnidRowInfo?.dnid} className="pmivr-input" disabled />
                                </div>
                                <div className="col">
                                    {
                                        dnidRowInfo?.showExt &&
                                        <>
                                            <PmivrLabel label="LEXT" tooltip={TOOLTIP.INFO.LEXT} />
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                onChange={(selected) => {
                                                    if (selected?.length) {
                                                        handleExt(selected[0])
                                                    }
                                                }}
                                                options={lextSuggestions}
                                                onInputChange={(text, _event) => {
                                                    if (text) {
                                                        handleExt(text);
                                                    }
                                                }}
                                                placeholder="Enter Lext values"
                                                className="pmivr-variable-input"
                                                multiple={false}
                                                selected={uiState?.lext ? [uiState?.lext] : []}
                                                disabled={clientFlowExists && !enableExt}
                                            />
                                            {uiState.message.extStatus === EXT_STATUS.VALID && <span className="text-success">
                                                Extension is available
                                                <i class="bi bi-check-circle text-success fs-6 valid-ext-icon px-1"></i>
                                            </span>}
                                            {uiState.message.extStatus === EXT_STATUS.INVALID && <span className="text-danger">
                                                {MESSAGES.ENTER_VALID_EXTENSION + ` ${selectedNumberInfo?.rangeStart} to ${selectedNumberInfo?.rangeEnd}`}
                                                <i class="bi bi-x-circle text-danger fs-6 invalid-ext-icon px-1"></i>
                                            </span>}
                                        </>
                                    }
                                </div>
                                <div className={`col-1 ext-btns d-flex align-items-center justify-content-end
                                     ${(uiState.message.extStatus === EXT_STATUS.VALID || uiState.message.extStatus === EXT_STATUS.INVALID) ?
                                        "my-auto pt-1" : "mt-auto"}`}>
                                    {dnidRowInfo?.showExt &&
                                        <div className="d-flex align-items-center justify-content-center">
                                            <PmivrTooltip message={TOOLTIP.CHECK_EXT}>
                                                <button type="button" onClick={async () => await checkExtValidity()}
                                                    className="pmivr-btn-secondary check-ext-btn text-nowrap"
                                                    disabled={!uiState.lext || (clientFlowExists && !enableExt)}>
                                                    Check Ext
                                                </button>
                                            </PmivrTooltip>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row mb-2">
                                <>
                                    <div className={`${dnidRowInfo?.showExt ? "col" : "col-md-4"}`}>
                                        <PmivrLabel label="Transfer Code" tooltip={TOOLTIP.INFO.TRANSFER_CODE} />
                                        <select
                                            id="transferChannelCode"
                                            className="pmivr-select"
                                            aria-label="Default Transfer code"
                                            value={transferChannelCode}
                                            onChange={(event) => { setTransferChannelCode(event.target.value); }}
                                        >
                                            <option value=""></option>
                                            <option value="0">0</option>
                                            <option value="*">*</option>
                                        </select>
                                    </div>
                                    <div className="col-1"></div>
                                </>
                            </div>
                            <div className="d-flex justify-content-between align-items-center pt-3">
                                <h6 className="pmivr-title mt-1">Transfer Number Configuration</h6>
                                <PmivrTooltip message={TOOLTIP.INFO.ADD_DNID_TRANSFER_VALUES}>
                                    <button type="button" onClick={handleTransferNumberAddRow}
                                        className="pmivr-wizard-data pmivr-btn-cancel"
                                        disabled={uiState.disableForm}>
                                        + Add Transfer Number
                                    </button>
                                </PmivrTooltip>
                            </div>
                            <table className="table pmivr-table header-fixed table-body-block border table-sm mt-2">
                                <thead>
                                    <tr>
                                        <th className="text-start ps-2">Language
                                            <PmivrTooltip message={TOOLTIP.INFO.TRANSFER_NUMBER_CONFIGURED_LANGUAGE}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th className="text-start">Transfer Number
                                            <PmivrTooltip message={TOOLTIP.INFO.TRANSFER_NUMBER_CONFIGURED_VALUE}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th className="text-start">Transfer Number Extension
                                            <PmivrTooltip message={TOOLTIP.INFO.TRANSFER_NUMBER_EXTENSION_CONFIGURED_VALUE}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th width="5%" className="text-start">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="dnid-info-box pmivr-scroll">
                                    {transferNumberConfig?.map((row, index) => (
                                        (row.language === '' || languages?.includes(row.language)) &&
                                        <tr key={index} className="align-items-end dnid-variable-heading p-0 m-0">
                                            <td className="dnid-variables">
                                                <select className="pmivr-select"
                                                    name="language"
                                                    value={row.language}
                                                    onChange={(e) => { handleTransferNumberChange(index, 'language', e.target.value) }}>
                                                    <option value='' disabled hidden>Select</option>
                                                    {languages.map((lang) => <option disabled={transferNumberConfig.some(item => item.language === lang)} value={lang} >{lang}</option>)}
                                                </select>
                                            </td>
                                            <td className="dnid-variables" >
                                                <input type="text" name={`value-${index}`} value={row.transferNumber}
                                                    onChange={(e) => handleTransferNumberChange(index, 'transferNumber', e.target.value)}
                                                    className="form-control pmivr-input"
                                                    placeholder="Value" />
                                            </td>
                                            <td className="dnid-variables" >
                                                <input type="text" name={`value-${index}`} value={row.transferNumberExtension}
                                                    onChange={(e) => handleTransferNumberChange(index, 'transferNumberExtension', e.target.value)}
                                                    className="form-control pmivr-input"
                                                    placeholder="Value" />
                                            </td>
                                            <td width="5%">
                                                <button type="button" disabled={uiState.disableForm}
                                                    onClick={() => handleTransferNumberRemoveRow(index)}
                                                    className="pmivr-btn-transparent pmivr-btn-icon mx-auto">
                                                    <i class="bi bi-trash fa-lg icon-font-size play-button"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-between align-items-center pt-3">
                                <h6 className="pmivr-title mt-1">Variables Against DNID</h6>
                                <PmivrTooltip message={TOOLTIP.INFO.ADD_DNID_VALUES}>
                                    <button type="button" onClick={handleAddRow}
                                        className="pmivr-wizard-data pmivr-btn-cancel"
                                        disabled={uiState.disableForm}>
                                        + Add Variable
                                    </button>
                                </PmivrTooltip>
                            </div>
                            <table className="table pmivr-table header-fixed table-body-block border table-sm mt-2">
                                <thead>
                                    <tr>
                                        <th className="text-start ps-2">Variable
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONIGURED_VARIABLE}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th className="text-start">Variable Type
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_VARIABLE_TYPE}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th className="text-start">Property Name
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_PROPERTY_NAME}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th className="text-start">Collection
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_COLLECTION}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th className="text-start">Value
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_INPUT_FIELD}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th width="5%" className="text-start">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="dnid-info-box pmivr-scroll">
                                    {dnidVariables?.map((row, index) => (
                                        <tr key={index} className="align-items-end dnid-variable-heading p-0 m-0">
                                            <td className="dnid-variables" >
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    className='pmivr-variable-input'
                                                    labelKey="selectedVariableInField"
                                                    onChange={(selected) => {
                                                        const value = selected.length && selected[0].customOption ?
                                                            selected[0].selectedVariableInField : selected[0];
                                                        handleChange(index, 'variable', value);
                                                    }}
                                                    options={variablesName}
                                                    onInputChange={(text, event) => handleChange(index, 'variable', text)}
                                                    placeholder="Variable"
                                                    multiple={false}
                                                    selected={row.variable ? [row.variable] : []}
                                                    allowNew
                                                />
                                            </td>
                                            <td className="dnid-variables">
                                                <select className="pmivr-select"
                                                    name="variableType"
                                                    value={row.variableType}
                                                    onChange={(e) => { handleChange(index, 'variableType', e.target.value) }}>
                                                    <option value='' disabled hidden>Select</option>
                                                    <option value='single'>Single</option>
                                                    <option value='complex'>Complex</option>
                                                </select>
                                            </td>
                                            <td className="dnid-variables" >
                                                <input type="text" name={`propertyName-${index}`} value={row.propertyName}
                                                    onChange={(e) => handleChange(index, 'propertyName', e.target.value)}
                                                    className="pmivr-input"
                                                    disabled={row.variableType !== 'complex'}
                                                    placeholder="Property Name" />
                                            </td>
                                            <td className="dnid-variables" >
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    className='pmivr-variable-input'
                                                    labelKey="selectedVariableInField"
                                                    disabled={row.variableType !== 'complex'}
                                                    onChange={(selected) => {
                                                        const value = selected.length && selected[0].customOption ?
                                                            selected[0].selectedVariableInField : selected[0];
                                                        handleChange(index, 'collection', value);
                                                    }}
                                                    onInputChange={(text, event) => handleChange(index, 'collection', text)}
                                                    options={variablesName}
                                                    placeholder="Collection"
                                                    multiple={false}
                                                    selected={row.collection ? [row?.collection] : []}
                                                    allowNew
                                                />
                                            </td>
                                            <td className="dnid-variables" >
                                                <input type="text" name={`value-${index}`} value={row.value}
                                                    onChange={(e) => handleChange(index, 'value', e.target.value)}
                                                    className="form-control pmivr-input"
                                                    placeholder="Value" />
                                            </td>
                                            <td width="5%">
                                                <button type="button" disabled={uiState.disableForm}
                                                    onClick={() => handleRemoveRow(index)}
                                                    className="pmivr-btn-transparent pmivr-btn-icon mx-auto">
                                                    <i class="bi bi-trash fa-lg icon-font-size play-button"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end mt-2">
                                <PmivrTooltip message={TOOLTIP.CANCEL}>
                                    <button type="button" onClick={() => closeAction()}
                                        className="pmivr-btn-secondary mx-1 p-2 me-3">
                                        Cancel
                                    </button>
                                </PmivrTooltip>
                                <button className={`d-flex justify-contect-end pmivr-btn-app p-2`}
                                    disabled={uiState.disableForm || !isFormValid() || (dnidRowInfo?.showExt && !uiState.lext)
                                        || (uiState.message.extStatus !== "valid" && enableExt)
                                    }
                                    type="submit" >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </>
    );
};

DnidVariablesModal.propTypes = {
    // info of the selected dnid row
    dnidRowInfo: PropTypes.shape({
        dnid: PropTypes.string.isRequired,
        lext: PropTypes.string.isRequired,
    }).isRequired,
    // function to close the dialog
    closeAction: PropTypes.func,
    // function to update the predefined values
    updateDnidRowValues: PropTypes.func,
    // array of dnid rows that has info of pre defined values and dnid and lext
    dnidNumbersList: PropTypes.array,
    // number pool containing info of range start and range end
    numberPool: PropTypes.array,
    // flag to check if client flow exists that is we are editing the flow
    clientFlowExists: PropTypes.bool,
    // flag to to check if we show input field of ext
    enableExt: PropTypes.bool,
    // index of the selected dnid modal
    dnidIndex: PropTypes.string,
    // languages for which we show transfer number
    languages: PropTypes.array
};

export default DnidVariablesModal;