import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { MESSAGES } from "../../../constants/messages";
import { CSS_CLASSES } from "../../../constants/css-classes";
import { FIELD_TYPES } from "../../../constants/field-types";
import { WIZARD_QUESTION_ID } from "../../../constants/wizard-questions-id";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { UPDATE_STATE } from "../../../constants/dnid";
import VoicePromptTab from "../../../components/common/voice-prompt-tab/voice-prompt-tab";
import { VoicePrompt } from "../../../models/voice-file";
import { DEFAULT_TRANSFER_VOICE_FILE } from "../../../config/config";
import StringUtil from "../../../util/string.util";
import _ from "lodash";
import WizardService from "../../../services/wizard.service";

/**
 * SetUpIVRQuestions Component
 * This component manages the setup of IVR questions for a client in multiple steps.
 * It supports both new setups and modifications of existing configurations.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.onUpdate - Function to update the state, based on the flag it will 
 * update the required state in parent component (wizard.js)
 * @param {boolean} props.isEdit.- Flag to indicate if we can edit the form based on the parent component
 * also helps to restore previous state when use click on the cancel button before saving the changes
 * @param {Array} props.updatedQuestion - List of updated questionnaire items for individual steps (categories).
 * @param {Array<{ children: Array<Object>, options: Array<Object>, validation: Array<Object>, flowTypeId: Array<String>, 
 * title: String, questionId:String, category: String, fieldType: String, _id: String
 * }>} props.questionnaire - array of all the questions helping to set the different transfer number for 
 * different language while configuring the DNID
 * @returns {JSX.Element} The rendered component.
 */
const SetUpIVRQuestions = ({ onUpdate, isEdit, updatedQuestion, questionnaire }) => {
    const location = useLocation();
    // getting latest state from redux
    const { businessCode, selectedFlowTypeInfo } = useSelector(state => state.client);
    // get the value of disabled the wizard from query param
    const searchParams = new URLSearchParams(location.search);
    // if coming from diagram page, then we get clientFlowExists as true, otherwise we get false
    const clientFlowExists = searchParams.get('clientFlowExists') === "true" || false;
    let counter = 1; // counter variable is to give dynamic id to the radio buttons and checkboxes

    /**
     * Get the Questions list
     * @param {Array} questions List of questions
     * @returns {Element} Questions in HTML format
     */
    const getQuestions = (questions) => {
        return questions.map((question, index) => {
            return (
                <>
                    <li key={question.title} disabled={(!question?.isEditable && clientFlowExists)}>
                        <span className="pt-1">{index + 1}</span>
                        <div className="list">
                            <div className="form-group ">
                                <div className="pmivr-label">
                                    <label className="mb-3">
                                        {question.heading || question.title}
                                    </label>
                                </div>
                                {question.heading && question.title ? (
                                    <div className="pmivr-label">
                                        <label className="mb-3">{question.title}</label>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="col-md-12">{getField(questions, question)}</div>
                            </div>
                        </div>
                    </li>
                </>
            );
        });
    };

    /**
     * Get the HTML code for the field to be shown, as per field type,
     * Like HTML code checkbox, radiobutton, text field, etc
     * @param {Object} question
     * @param {Array} questions
     * @param {number} index
     *  @returns {React.Component} Html element to render 
     */
    const getField = (questions, question = {}) => {
        if (!question.options && !question.nextchild) {
            if (![FIELD_TYPES.TEXT, FIELD_TYPES.VOICE_PROMPT].includes(question.fieldType)) {
                return <></>;
            }
        }

        let fieldContent = "";

        switch (question.fieldType) {
            case FIELD_TYPES.DROPDOWN: {
                fieldContent = (
                    <select
                        className="pmivr-select select-sm custom-drop "
                        value={question.value}
                        onChange={(e) => {
                            updateInputValue(question, questions, e.target.value);
                        }}
                    >
                        {question.options.map((option, _keyIndex) => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.name}
                                </option>
                            );
                        })}
                    </select>
                );
                break;
            }
            case FIELD_TYPES.RADIO: {
                fieldContent = (
                    <>
                        {question.options.map((option, optionIndex) => {
                            return (
                                <div key={optionIndex} className="form-check pmivr-check-radio form-custom-inline">
                                    <input
                                        className="form-check-input"
                                        id={counter}
                                        type="radio"
                                        checked={question.value === option.value ? "checked" : ""}
                                        value={option.value}
                                        onChange={(e) => {
                                            updateInputValue(question, questions, e.target.value);
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor={counter++}>
                                        {option.name}
                                    </label>
                                </div>
                            );
                        })}
                    </>
                );
                break;
            }
            case FIELD_TYPES.CHECKBOX: {
                fieldContent = (
                    <>
                        {question.options.map((option, _index) => {
                            return (
                                <div className="row" key={_index}>
                                    <div className="col-md-1">
                                        <div className="form-check pmivr-check-radio form-custom-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={counter}
                                                checked={
                                                    (question.value || []).indexOf(option.value) > -1
                                                        ? "checked"
                                                        : ""
                                                }
                                                value={option.value}
                                                onChange={(e) => {
                                                    updateInputValueCheck(question, questions, e.target.value);
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor={counter++}>
                                                {option.name}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-11 p-0">
                                        {(question.fieldType === "checkbox" && (question.children).length) ? (
                                            getCheckboxChildren(question.children, questions, question.value, option.value)
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                );
                break;
            }
            case FIELD_TYPES.TEXT: {
                if (question.callVariableName === "wizardCfg.transferCfg.transferNumber" || question.callVariableName === "wizardCfg.transferCfg.transferNumberExtension") {
                    const languages = WizardService.getLanguagesFromWizard(questionnaire);
                    fieldContent = (
                        <>
                            {languages.map((lang) => {
                                return (
                                    <div key={lang} className="form-check-inline">
                                        <label className="form-check-label">{question.callVariableName === "wizardCfg.transferCfg.transferNumberExtension" ? `Transfer Number Extension [${lang}]` : `Transfer Number [${lang}]`} </label>
                                        <input className="form-control input-sm" id="inlineText1" type="text" name="fav3"
                                            disabled={StringUtil.toBoolean(question.disabled)}
                                            value={
                                                (typeof question.value === "object")
                                                    ? question.value[lang] || ""
                                                    : question.value || ""
                                            }
                                            onChange={(e) => {
                                                // if value is string, then it means that the numbers are configured not as per language
                                                // so, need to update the numbers as per language
                                                if (typeof question.value === "string") {
                                                    const savedNumber = question.value;
                                                    question.value = {};
                                                    // assigning the initial same number to all the languages
                                                    languages.forEach((lang) => {
                                                        question.value[lang] = savedNumber;
                                                    });
                                                }
                                                // updating the number for the current language
                                                question.value = { ...question.value, [lang]: e.target.value };
                                                const updatedinputQuestionaaire = _.values(_.merge(_.keyBy(questionnaire, "_id"), _.keyBy(questions, "_id")));
                                                onUpdate({ questions: [...updatedinputQuestionaaire], flag: UPDATE_STATE.SET_QUESTIONNAIRE });
                                            }} />
                                    </div>
                                );
                            })}
                        </>
                    );
                } else {
                    fieldContent = (
                        <div className=" form-check-inline">
                            <input
                                className="form-control input-sm"
                                id="inlineText1"
                                type="text"
                                name="fav3"
                                disabled={StringUtil.toBoolean(question.disabled)}
                                value={question.value}
                                onChange={(e) => {
                                    updateInputValue(question, questions, e.target.value);
                                }}
                            />
                        </div>
                    );
                }
                break;
            }
            case FIELD_TYPES.VOICE_PROMPT: {
                let defaultPath = "";
                if (question?.callVariableName === "wizardCfg.transferCfg.transferVoiceFile") {
                    defaultPath = DEFAULT_TRANSFER_VOICE_FILE.ZERO_OUT;
                } else if (question?.callVariableName === "wizardCfg.transferCfg.outsideBusinessHourVoiceFile") {
                    defaultPath = DEFAULT_TRANSFER_VOICE_FILE.OUTSIDE_BUSINESS_HOURS;
                } else if (question?.callVariableName === "wizardCfg.transferCfg.confirmTransferVoiceFile") {
                    defaultPath = DEFAULT_TRANSFER_VOICE_FILE.CONFIRM_TRANSFER;
                }
                const languages = WizardService.getLanguagesFromWizard(questionnaire);
                const voicePromptInfo = getTransferVoiceFileInfo(question?.value, defaultPath);
                fieldContent = (
                    <VoicePromptTab
                        voicePromptInfo={voicePromptInfo} languages={languages} showAddPromptBtn={true} showVariableOption={true}
                        businessCode={businessCode} dirName={selectedFlowTypeInfo?.flowName}
                        onUpdate={(updatedPromptsInfo) => {
                            updateInputValue(question, questions, JSON.stringify(updatedPromptsInfo));
                        }} />
                );
                break;
            }
            default:
                fieldContent = <></>;
                break;
        }

        return (
            <>
                {fieldContent}
                {/* in case if it is not checkbox then render child questions*/}
                {(question.fieldType !== "checkbox" && (question.childRenderOption === question.value)) ? (
                    getChildrenField(question.children, questions)
                ) : (
                    <></>
                )}
                {/* if checkbox then it gives array value so we need to check its length */}
            </>
        );
    };

    /**
     *  Update the field value like text, radio, dropdown
     * @param {Object} question
     * @param {Array} questions
     * @param {string} newValue
     */
    const updateInputValue = (question, questions, newValue) => {
        question.value = newValue;
        const updatedinputQuestionaaire = _.values(_.merge(_.keyBy(questionnaire, "_id"), _.keyBy(questions, "_id")));
        onUpdate({ questions: updatedinputQuestionaaire, flag: UPDATE_STATE.SET_QUESTIONNAIRE });
    };

    /**
     *  Update the checkbox value
     * @param {Object} question
     * @param {Array} questions
     * @param {string} newValue
     */
    const updateInputValueCheck = (question, questions, newValue) => {
        const isNewValueInValues = question.value.find((x) => x === newValue);
        if (isNewValueInValues) {
            question.value = question.value.filter((n) => {
                return n !== newValue;
            });
        } else {
            question.value = [...question.value, newValue];
        }
        // updating the questionaaire with the latest changes
        const updatedCheckQuestionaaire = _.values(_.merge(_.keyBy(questionnaire, "_id"), _.keyBy(questions, "_id")));
        onUpdate({ questions: updatedCheckQuestionaaire, flag: UPDATE_STATE.SET_QUESTIONNAIRE });
    };

    /**
     * Renders the child fields in case of check box on the screen
     * @param {Array} subQuestions List of child questions
     * @param {Array} questions List of questions
     * @param {Array} questionValue Value of the question selected
     * @param {Array} optionValue value of options that is display, it is not the selected value of question
     * @returns {React.Component} Html element to render 
     */
    const getCheckboxChildren = (subQuestions, questions, questionValue, optionValue) => {
        return subQuestions.map((subQuestion, _index) => {
            // to check for the child question belonging to its parent question
            const isQuestionChild = subQuestion.key === optionValue;
            const isVoiceTypeIncluded = questionValue.includes(subQuestion.key);
            return (
                isQuestionChild &&
                <ol key={subQuestion.title} className={`${isVoiceTypeIncluded ? "sub-questions" : "sub-questions pmivr-disabled"}`}>
                    <li>
                        <div className="pmivr-label">
                            <label className="mb-3">{subQuestion.title}</label>
                        </div>
                        {getField(questions, subQuestion)}
                    </li>
                </ol >
            );
        });
    };

    /**
     * Renders the child fields (text fields, radio buttons, etc.) on the screen
     * @param {Array} subQuestions List of questions
     * @param {Array} questions List of questions
     * @returns {React.Component} Html element to render 
     */
    const getChildrenField = (subQuestions, questions) => {
        if (!subQuestions) {
            return <></>;
        }
        return subQuestions.map((subQuestion, _index) => {
            // get if user has confirm the transfer of voice file
            const isConfirmTransfer = getConfirmTransferFromWizard(questionnaire);
            if (subQuestion.callVariableName === "wizardCfg.transferCfg.confirmTransferVoiceFile" &&
                !StringUtil.toBoolean(isConfirmTransfer)) {
                return null;
            }
            return (
                <ol key={subQuestion.title} className="sub-questions">
                    <li>
                        <div className="pmivr-label">
                            <label className="mb-3">{subQuestion.title}</label>
                        </div>
                        {getField(questions, subQuestion)}
                    </li>
                </ol>
            );
        });
    };

    /**
     * Get the confirmTransferCall from wizard, if user has selected to confirm
     * @param {Array} config list of questions
     * @returns {Boolean} value of confirm transfer to be done or not
     */
    const getConfirmTransferFromWizard = (config) => {
        const question = config.find(item => item.questionId === WIZARD_QUESTION_ID.TRANSFER_CALL);
        if (question && question.children) {
            const child = question.children.find(child => child.callVariableName === 'wizardCfg.transferCfg.confirmTransferCall');
            return child ? child.value : true;
        }
        return true;
    };

    /**
     * Get the zero out and outside business hours voice file information 
     * @param {string} voiceFile voice file value from wizard question
     * @param {string} defaultPath default path of voice file
     * @returns {{en:Array,es:Array}} Returns the voice file prompt info
     */
    const getTransferVoiceFileInfo = (voiceFile, defaultPath) => {
        const languages = WizardService.getLanguagesFromWizard(questionnaire);
        let transferVoiceFileInfo = voiceFile;
        // transfer voice file is a string initially and need to check if it is a valid object or not
        if (typeof (transferVoiceFileInfo) === "string" && StringUtil.isValidJson(transferVoiceFileInfo)) {
            transferVoiceFileInfo = JSON.parse(transferVoiceFileInfo);
        }
        const tmpTransferVoiceFilePromptInfo = {};
        if (transferVoiceFileInfo) {
            languages.forEach((lang) => {
                // if zero out voice file is not string then then its a prompt else create a prompt object
                if (typeof (transferVoiceFileInfo) !== "string") {
                    tmpTransferVoiceFilePromptInfo[lang] = transferVoiceFileInfo[lang];
                } else {
                    tmpTransferVoiceFilePromptInfo[lang] = [new VoicePrompt({
                        filePath: transferVoiceFileInfo,
                        voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY
                    })];
                }
            });
        } else {
            languages.forEach((lang) => {
                tmpTransferVoiceFilePromptInfo[lang] = [new VoicePrompt({
                    filePath: defaultPath,
                    voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY
                })];
            });
        }
        return tmpTransferVoiceFilePromptInfo;
    }

    return (
        <div className="set-up-questions">
            {/* Title Section */}
            <div className="d-flex justify-content-between align-items-center">
                <div className={clientFlowExists ? `${CSS_CLASSES.HIDE_DISPLAY}` : `${CSS_CLASSES.INLINE_DISPLAY}`}>
                    <h5 className="pmivr-title px-3 pt-2">Setup IVR to create new client</h5>
                </div>
                <div className={clientFlowExists ? `${CSS_CLASSES.INLINE_DISPLAY} d-flex` : `d-flex ${CSS_CLASSES.HIDE_DISPLAY}`}>
                    <h5 className="pmivr-title px-3 pt-2">Setup IVR for client</h5>
                </div>
            </div>
            {/* Warning Message */}
            {clientFlowExists && !isEdit &&
                <div className="alert alert-warning ms-3 me-2 mb-0 mt-1" role="alert">
                    <i className="bi-exclamation-triangle-fill"></i> <strong>{MESSAGES.WIZARD_INFO}</strong>
                </div>
            }
            {/* Questions Section */}
            <div className={`${isEdit ? "pmivr-disabled" : ""} px-3 pt-0`} id='wizard-questions'>
                <ul className="questions">{getQuestions(updatedQuestion)}</ul>
            </div>
        </div>
    )
}

// PropTypes for type-checking
SetUpIVRQuestions.propTypes = {
    // It will update the required state in parent component(wizard.js) based on flag
    onUpdate: PropTypes.func,
    // List of updated questionnaire items for individual steps(categories)
    updatedQuestion: PropTypes.array.isRequired,
    // Helps to restore previous state when use click on the cancel button before saveing the changes
    isEdit: PropTypes.bool,
    // Helping to set the different transfer number for different language while configuring the DNID
    questionnaire: PropTypes.arrayOf(
        PropTypes.shape({
            children: PropTypes.arrayOf(PropTypes.shape),
            options: PropTypes.arrayOf(PropTypes.shape),
            validation: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })),
            flowTypeId: PropTypes.arrayOf(PropTypes.string).isRequired,
            title: PropTypes.string.isRequired,
            questionId: PropTypes.string.isRequired,
            category: PropTypes.string,
            fieldType: PropTypes.string,
            _id: PropTypes.string.isRequired,
        })
    )
};
export default SetUpIVRQuestions;