// messages that can been used across the app
export const MESSAGES = {
    EMPTY_STRING: "",
    SELECT_STRING: "Select",
    NO_NAME: "No Name",
    NO_COMMENTS: "No Comments",
    CONFIG_KEYS_NOT_FOUND: "Please contact admin - Client Configs keys Not Found",
    EXPRESSION_WARNING: "Elements With console.log",
    WIZARD_UPDATE: "Wizard updated successfully",
    LOAD_FLOW_INFO_ERROR: "Unable to load flow info",
    LOAD_FLOW_ERROR: "Unable to load flow",
    SOMETHING_WENT_WRONG: "Something went wrong. Please contact admin.",
    ATLEAST_SELECT_ONE_LANG: "At least one language must be selected.",
    SAVED_SUCCESSFULLY: "Saved Successfully",
    PUBLISH_FLOW_INPROGRESS: "Publish flow in progress",
    SESSION_EXPIRED: "Session Expired! Please login",
    INVALID_CREDENTIALS: "Invalid Email / Password",
    FILL_CREDENTIALS_PROPERLY: "Please fill email/password properly",
    UPDATE_CONDITION_ERROR: "Unable to update condition. Try Again",
    EXISTING_BUSINESS_CODE: "Business Code already exists",
    UNABLE_TO_LOAD_VARIABLES: "Unable to load variables.",
    EXTENSION_AVAILABLE: "Ext is available",
    EXTENSION_ALREADY_EXISTS: "Extension already present.",
    DNID_UNAVAILABLE: "No DNID number is present. Please contact to admin",
    ERROR_EXTENSION: "Error verifying ext number",
    ENTER_VALID_EXTENSION: "Please enter a valid Ext number within range",
    FILE_UPLOAD_SUCCESS: "File Uploaded Successfully",
    COPIED_TO_CLIPBOARD: "Copied to Clipboard",
    API_KEY_DELETED_SUCCESS: "API Key Deleted Successfully",
    FLOW_ALREADY_PRESENT: "Flow already present",
    NO_CHAT_FLOWS_CREATED: "No chat flow created yet",
    NO_CHAT_FLOWS_PENDING: "No chat flow pending",
    VOICE_FILE_UPLOAD_CORRECT_FORMAT: "You can only upload .wav files",
    ENTER_CUSTOM_FLOW_NAME: "Enter custom flow name",
    FLOW_NOT_CONFIGURED: "This flow is not configured yet!. Please configure it.",
    VOICE_FILE_NOT_CONFIGURED: "No voice file configured yet",
    DISABLE_TASK_WARNING: "Turning off this node could make the conversation stop because the information it provides might be needed for the next nodes of the conversation",
    ENABLE_TASK_WARNING: "Please confirm to enable the node?",
    RE_USABLE_INFORMATION: "Exporting this as re-usable element will help you to re-use in your flow.If already exported then it will update your re usable element.",
    PAGE_NOT_FOUND: "Oops!! Page not found",
    PAGE_FORBIDDEN: "Oops!! Forbidden",
    DRAFT_CLOSE_MSG: "You can close this window",
    TTS_TEXT_WARNING: "Invalid character",
    TTS_SPEED_WARNING: "Speed must be in a range of 10 to 100",
    SESSION_VALIDATED_SUCCESS: "Session validated successfully",
    UPDATE_TO_LATEST_FLOW_HEADING: "Base Flow Update",
    REBASED_SUCCESSFULLY: "Flow updated successfully, and is being added in your draft list",
    WIZARD_INFO: "You can delete language selection node or re-use from sub processes based on your selection.",
    XML_FILE_UPLOADED: "XML File uploaded successfully",
    DOWNLOADED_SUCCESSFULLY: "Downloaded successfully",
    UPLOAD_XML_FILE: "Upload XML file",
    UPLOAD_XML_FILE_INFO: "Please upload a valid XML file only. This will create a draft version for the uploaded xml.",
    SYSTEM_VOICE_FILE_UPDATED: "System voice file updated",
    FILTER_FLOW_VERSIONS: "Select flags to filter flow versions",
    REUSABLE_ELEMENT_DELETED: "Re usable element deleted successfully",
    EXPORT_REUSABLE_ELEMENT: "Element exported successfully",
    DATE_TIME_FORMAT: "DD-MM-YYYY",
    DRAFT_OPTION: {
        DELETED_SUCCESSFULLY: "Draft Option deleted successfully"
    },
    DNID_INFO: {
        SELECT_DNID: "Please select a DNID",
        SAVED_SUCCESSFULLY: "DNID details saved successfully.",
        ALREADY_CONFIGURED: "DNID already configured for the flow with this extension",
        SAME_VARIABLE_USED: "You cannot use same variable twice for same dnid."
    },
    ERR: {
        NO_DNID: "No DNID configured yet!",
        CONFIGURE_EXT: "Kindly configure extension",
        INPUT_RANGE_VALIDATION: "Invalid input regex validation.",
        DRAFT_ERR: "Drafting already in progress",
        WIZARD_LOAD: "Unable to load wizard.",
        SUBPROCESS_EXISTS: "Sub Process already exists",
        REUSABLE_ELEMENT_DELETE_ERR: "Error deleting re usable element",
        SUBPROCESS_NOT_ALLOWED: "Since only one language is configured so Language Selection cannot be created",
        WIZARD_UPDATE_ERR: "Error updating wizard",
        NUMBER_POOL_FETCH_ERR: "Error fetching number pool",
        INCORRECT_DNID_RANGE: "Range is not correct",
        WIZARD_SAVE: "Unable to save wizard changes.",
        SAVE_DIAGRAM: "Unable to save diagram",
        VOICE_FILES_LOAD: "Unable to load voice files.",
        DEPLOYMENT_ENVIRONMENT_NOT_FOUND: "Unable to find the selected environment for the biller",
        SELECT_ENVIRONMENT: "Please select the environment",
        BUSINESS_CODE_NOT_EXISTS_ENVIRONMENT: "Biller does not exist in selected environment",
        BUSINESS_CODE_INVALID: "Invalid Business Code. Try again searching.",
        CUSTOM_FLOW_NAME_INVALID: "Flow name already taken or unauthorized for use. Try different name.",
        CUSTOM_FLOW_VALIDATION: "Flow name requires a minimum of 3 characters.",
        CLIENT_DOES_NOT_EXISTS: "This client does not exists in the system. Please contact admin.",
        INVALID_JSON_FILE: "Please upload a valid json file.",
        INVALID_XML_FILE: "Please upload a valid xml file.",
        UNAUTHORIZED: "Unauthorized",
        EXISTING_DNID: "Phone number already exists.",
        EXISTING_RECORD: "Record already exists",
        EXISTING_EMAIL: "Record already exists for the entered email.",
        XML_FILE_REQUIRED: "XML file is required",
        NO_RECORDS: "No records available",
        CHAT_FLOW_ERR: "Unable to load chat flows",
        UNABLE_TO_DELETE_API_KEY: "Unable to delete API Key",
        UNABLE_TO_REGENERATE_API_KEY: "Unable to regenerate API Key",
        INVALID_EMAIL: "Invalid Email",
        INVALID_DNID: "Please enter valid 10 digits phone number",
        PHONE_NUMBER_REQUIRED: "Phone Number is required",
        VOICE_FILE_UPLOAD: "Unable to upload audio file",
        FIELD_REQUIRED: "This field is required",
        VARIABLES_NOT_AVAILABLE: "Variables not available",
        INVALID_VALIDITY_DAYS: "Expiry time must be only positive numbers (no decimal)",
        RECENTLY_UPDATED_BUSINESS_CODE_NOT_FOUND: "Unable to get recently updated business code",
        FILE_UPLOAD: "File Upload Failed",
        JSON_PARSE_ERROR: "Error parsing AUTH JSON",
        NO_FLOW_SELECTED: "No flow selected, Please select the flow.",
        CLIENT_FLOWS_LOAD: "Unable to load client flows",
        FAILED_DRAFT: "Failed to draft the published flow.",
        NOT_VALID_XML: "Xml to draft is not a valid xml.",
        AUDIO_FILE_ERROR: "Unable to play the Audio File",
        AUDIO_FILE_DOWNLOAD_ERROR: "Unable to download the Audio File",
        INVALID_VERSION_ID: "Invalid version id",
        INVALID_EXPRESSION: "Restricted code in expression. Please remove.",
        EXPORT_REUSABLE_ELEMENT: "Error exporting element",
        PASSWORD_LENGTH: "Enter at least 8 characters for the password",
        PASSWORDS_MUST_MATCH: "Passwords must match",
        PASSWORD_PATTERN: "Password must contain: at least one lowercase letter (a-z), at least one uppercase letter (A-Z), at least one number (0-9) and at least one special character(*,&,% etc.)",
        UPLOAD_XML_FILE: "Please select an XML file.",
        INVALID_URL: "Please enter a valid URL",
        EXISTING_LABEL: "Please enter unique label and URL"
    }
}

// Information of property fields
export const TOOLTIP = {
    LOGIN: "Login",
    HISTORY: "History",
    CANCEL: "Cancel",
    CREATE: "Create",
    RELOAD: "Reload",
    EDIT: "Edit",
    DOWNLOAD: "Download flow",
    CREATE_FLOW: "Create Flow",
    CHECK_EXT: "Check ext validity",
    UPDATE_WIZARD: "Enable wizard to edit",
    CREATE_SUB_PROCESS: "Create Sub-Process",
    DOC_VERSION_PAGE: "Go To Doc Version Page",
    CONFIGURE_DNID_VALUES: "Configure values for DNID",
    REFRESH_PUBLISH_VERSION_STATUS: "Refresh publish version status",
    PUBLISHED_FLOW_IN_PROGRESS: "Flow publishing is in progress. Please click on refresh button to check its status",
    PUBLISHED_FLOW_FAILED: "Flow publishing failed. Please try again",
    VARIABLE_INFO: "Variable name in which you want to store the user input",
    RETRY_INFO: "Number of times you want the user to retry before moving to the next step",
    MAX_DIGIT_INFO: "Maximum number of input characters allowed",
    TIMEOUT_INFO: "Please enter (the time in seconds) for which IVR will wait for the user to enter a valid input.",
    INVALID_INPUT_INFO: "This file will play if user enter wrong detail",
    VOICE_TYPE_PATH_INFO: `You can modify the path for your own voice file. If a prefix with a path is already provided, 
    complete the path. Otherwise, you can remove the existing prefix and add your own.`,
    FILE_PATH_INFO: "Type in the audio file name and attach it to the relevant node",
    AUDIO_PLAY: "Click To Play",
    AUDIO_PAUSE: "Click To Pause",
    DELETE: "Click To Delete",
    DOWNLOAD_FILE: "Click To Download",
    PASSWORD_VISIBILITY: "Click to toggle password visibility",
    GENERATE_RANDOM_PASSWORD: "Generate random password",
    INFO: {
        VOICE_FILE_VARIABLE: "Variable to play with voice file. Example: press-1 to repeat account number ending with" +
            " <last-4-digit-account-number>",
        VARIABLE_PROMPT_PATTERN: "Used to read variables based on a specified pattern. " +
            "For numeric inputs, specify the pattern in sets (e.g., 4,4,4,4), with a pause after each set.  " +
            "The system will read back the input based on the configured pattern. If the input exceeds the specified " +
            "length, it will be read continuously without pauses.",
        VARIABLE_DATE_PATTERN: "Specify the parts of date to prompt, available options: month, year, day, fullYear. " +
            "Example: (month, day, year), (day, month, year), (month, year). " +
            "Difference between year and fullYear: fullYear will readback specific full year as entered by the user, but year will readback the year of current century. " +
            "Example: fullYear: 1949, but year: 25 which means 2025.",
        DNID: "DNID of the flow",
        LEXT: "Extension of the flow",
        TRANSFER_CODE: "Transfer code for transfering the call in the flow",
        USER_OPTION_OCCUPIED: "Option occupied for transfer code",
        ADD_DNID_VALUES: "Add pre defined variables against DNID",
        ADD_DNID_TRANSFER_VALUES: "Add transfer number values",
        TRANSFER_NUMBER_CONFIGURED_LANGUAGE: "Language of the transfer number configured",
        TRANSFER_NUMBER_CONFIGURED_VALUE: "Value of the transfer number configured",
        TRANSFER_NUMBER_EXTENSION_CONFIGURED_VALUE: "Value of the transfer number extension configured",
        DNID_CONIGURED_VARIABLE: "Name of the variable whose value is predefined",
        DNID_CONFIGURED_VARIABLE_TYPE: "Type of the variable single or complex. Single refers to assigning the value directly " +
            "and complex means we will give collection name from where object will be assigned to variable",
        DNID_CONFIGURED_PROPERTY_NAME: "If complex variable then property name to be defined to access that value",
        DNID_CONFIGURED_COLLECTION: "Collection for reading the value based on property name",
        DNID_CONFIGURED_INPUT_FIELD: "Value of the variable.Example in case of english language it will be en.",
        UPDATE_FLOW: "Upload the latest flow for new migration changes in your flow to save as draft",
        ALREADY_MIGRATED_FLOW: "Already migrated the flow",
        MIGRATE_MANUALLY: "Migrating to latest draft flow and you can publish that flow as latest flow",
        IS_MANUAL_MIGRATION: "If enabled then flow will be marked as migrated latest base flow.",
        INVALID_VOICE_FILE: "Enter invalid voice file which will be played on entering invalid input.",
        CONFIRMATION_INPUT_VOICE_FILE: "Enter the voice file to be played while confirmation of input. If not configured, "
            + "then system file (PRESS_1_OR_PRESS_2) will be played for confirming input.",
        IS_ALPHA_NUMERIC_PATTERN: "If enabled then user can enter alpha numeric input",
        IS_INPUT_SEPERATOR: "If enabled then user can enter seperator between alpha numeric pattern defined for input",
        IS_INPUT_PREFIX: "If enabled , user can define prefix before user input.",
        IS_SPEECH_INPUT: "If enable speech input , user can give input using speech and keypad input will not be allowed.",
        NO_SPEECH_INPUT_TIMEOUT: "Maximum time (in ms) for which speech input system will wait for the speech input from the user before it stops waiting. "
            + "Example: value = 5000, then system waits for 5 seconds for speech input start.",
        MAX_SPEECH_TIMEOUT: "Maximum time (in ms), the system will wait for speech input, before considering the input as timed out or terminating the listening process. "
            + "Example: value = 10000, then system waits for 10 seconds for completion of speech input.",
        IS_READBACK_INPUT: "If enabled then user can define prompt digit count , data type to read back",
        IS_REGEX_VALIDATION: "If enabled then user will be allowed to enter regex validation and if not then allowed minDigits and maxDigits input.",
        SKIP_IF_SINGLE_USER_OPTION: "If enabled the control will not be played if there is only one option configured, and the single value will be selected as response variable.",
        END_CALL_IF_INVALID_INPUT_AFTER_RETRY: "If enabled, the call will end on getting invalid input for configured "
            + "number of retries. If disabled, then call will not end and the user can configure the next course "
            + "of action after getting invalid input for configured number of retries.",
        ALLOW_INPUT_DURING_READBACK: "If enabled, user can confirm the user input in between of read back",
        USER_INPUT_REGEX_VALIDATION: "Specify the regex that defines range to validate user input",
        SUBMIT_INPUT_WITH_HASH_KEY_ENABLED: "Input will only be submitted once you press the # key as the completion character. "
            + "Ensure that all inputs are finalized with # to confirm your selection.",
        USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT: "Enable this option to allow system to accept empty user input values for submission. "
            + "If empty values are allowed then 'Submit with # key' should be enabled to mark the completion of input.",
        SERVICE_TASK_INPUT_VARIABLE: `If you want to refer to a variable use $ prefix before the value of key. eg. {prop : "$variableName"}`,
        SERVICE_TASK_RESPONSE_VARIABLE: `Use comma(,) to add variable`,
        SERVICE_TASK_METHOD_NAME: `Select the AGI method name where the backend business logic is implemented`,
        SERVICE_EXPRESSION: 'Please enter the expression to be implemented. Add "return" to return the value and '
            + 'get the returned value saved in configured response variable. There are some restricted expressions '
            + 'like uiCode (document, window, console, alert, confirm, prompt ),  '
            + 'dbCode (importing - mongodb, mongoose, sql, sqlite3, mysql, postgresql, oracle), '
            + 'sensitive data (password, api_keys, access_token, etc), dynamic code execution (eval(), new Function()), '
            + 'delete commands(deleteFile, delete, unlink, rm, remove), Infinite loops, '
            + 'downloadData(fetch, XMLHttpRequest, axios, download). Restricted code will be highlighted in editor, '
            + 'if added. You cannot type $, as it is the reserved keyword for identifying flow and'
            + ' system variables. Press (ctrl + space) to have suggestions. Also, you can use js functions '
            + '( map | forEach | length | slice | filter | push | pop | substring | includes | sort | splice | find | toString | concat ). '
            + 'Example: return $amount + $tax + 30',
        DATA_FROM_COLLECTION: "When you turn on this feature, you can choose which information you want to bring together from a collection",
        URL_PARAMS: "Input format: param1,param2\n" +
            "So here, the actual value of param1 and param2 at runtime will be appended in the API url: apiURL/param1/param2",
        QUERY_PARAMS: `Query Params format: {"param1":"value1","param2":"value2"}.` +
            ` Here, the keys in object act as query and the values act as query values,` +
            ` URL formed: apiUrl?param1=value1&param2=value2`,
        API_URL: "They usually start with \"http://\" or \"https://\", followed by the actual URL",
        API_METHOD: "Method for the API call to retrieve information, updating URL etc.",
        USER_INPUT_MAX_VALUE: "Please define the maximum value user can input or speak for current user input. "
            + "Before that, please select the value type, like variable or constant value.",
        USER_INPUT_MIN_VALUE: "Please define the minimum value user can input or speak for current user input. "
            + "Before that, please select the value type, like variable or constant value.",
        USER_INPUT_MAX_VALUE_TYPE: "If variable selected, then select the variable that will specify the max value for input. "
            + "If value selected, then define the integer max value for input",
        USER_INPUT_MIN_VALUE_TYPE: "If variable selected, then select the variable that will specify the min value for input. "
            + "If value selected, then define the integer min value for input",
        USER_INPUT_MAX_DIGITS: "Please define the maximum number of digits users can input or speak to ensure compatibility and efficient processing within the system.",
        USER_INPUT_MIN_DIGITS: "Kindly define the minimum number of digits users can input or speak, ensuring accuracy and completeness in data collection or recognition.",
        USER_INPUT_VARIABLE: "Create a designated variable to store the information entered by the user.",
        USER_INPUT_RETRY_COUNT: "Set the maximum number of allowed retry attempts for user input. The default retry count is 3.",
        USER_INPUT_TIMEOUT: "Specify the duration (in ms) for keypad input (non speech input), after which the system will time out if no user input is received. "
            + "Also, if input is defined, the system gives given time for completion of input. Example : value: 6000, "
            + "then the system will wait for 6 seconds after playing the voice file before proceeding to the next step in case of no input. "
            + "Also, if input is given, then the system will wait for 6 seconds after the last character is entered, allowing time for any additional input.",
        USER_OPTION_INPUT_TIMEOUT: "Specify the duration (in ms) for non-speech input, after which the system will time out if no user input "
            + "is received. If not configured, default timeout value will be 3000 ms. Example : value: 3000, "
            + "then the system will wait for 3 seconds after playing the voice file before proceeding to the next step in case of no input.",
        USER_INPUT_READBACK_PATTERN: "Used to read user inputs based on a specified pattern. " +
            "For numeric inputs, specify the pattern in sets (e.g., 4,4,4,4), with a pause after each set.  " +
            "The system will read back the input based on the configured pattern. If the input exceeds the specified length, it will be read continuously without pauses. ",
        USER_INPUT_PRECISION: "Specify the precision allowed for the user input" +
            "Example: If input is 56.999, and precision is 2, then it will be an invalid input, but for input 56.99 it is valid with precision 2",
        USER_INPUT_DATE_READBACK_PATTERN: "Specify the parts of date to readback, available options: month, year, day, fullYear. " +
            "Example: (month, day, year), (day, month, year), (month, year). " +
            "Difference between year and fullYear: fullYear will readback specific full year as entered by the user, but year will readback the year of current century. " +
            "Example: fullYear: 1949, but year: 25 which means 2025.",
        SERVICE_IMPL_EXPRESSION_RESPONSE_VARIABLE: "Specify the response variable where the value returned by the expression will be stored",
        SERVICE_IMPL_METHOD_RESPONSE_VARIABLE: "Specify the response variable where the outcome of the method call will be stored",
        AUTH_URL: "Endpoint where users are directed to log in and prove their identity.",
        AUTH_RESPONSE_VARIABLE: "Refers to a variable or parameter that is part of the response received from an authentication",
        AUTH_PARAMS_JSON_STRING: `Query Params format: {"param1":"value1","param2":"value2"}.` +
            ` Here, the keys in object act as query and the values act as query values,` +
            ` URL formed: authUrl?param1=value1&param2=value2`,
        TIMEOUT: "Define the maximum duration (in seconds) during which the user can record their message before the call is automatically disconnected.",
        AUDIO_FORMAT: "Configure the format in which the recorded audio will be saved.",
        IS_INSTANT_RESPONSE_USER_OPTION: "If enabled, the system can accept the user option input while options are playing. "
            + "Otherwise, option input will be accepted only after all the options are played.",
        OPTION_VARIABLE: "This configuration allows you to store the user's selected option in a designated variable",
        RETRY_COUNT: "Defines how many times a user can attempt a selection before moving on in the IVR flow. The default value is 3.",
        DYNAMIC_OPTION_MAPPING_FIELD: "Defines the field name from the option list element to which the voice file will be mapped to.",
        TRANSFER_NUMBER: "Please provide the phone number or extension to which incoming calls will be seamlessly transferred for efficient handling and streamlined communication within your organization.",
        TRANSFER_NUMBER_EXTENSION: "Please provide the extension to which incoming calls will be seamlessly transferred for efficient handling and streamlined communication within your organization.",
        BUSINESS_START_TIME: "Kindly indicate the start time when the business will be ready to receive incoming calls",
        BUSINESS_END_TIME: "Kindly indicate the end time when the business will stop receiving incoming calls",
        HOLIDAYS_LIST: "Please define holiday dates in the mm/dd/yyyy format. This ensures uniformity and simplifies tracking, aiding in effective planning and coordination across the organization.",
        ZERO_OUT_VOICE_FILE: "Customize a voice file to play for callers on hold during transfer, offering reassurance and relevant information to enhance their waiting experience.",
        OUTSIDE_OF_BUSINESS_HOURS: "Select a specific voice file to be played for callers received outside of configured business hours, providing them with relevant information or alternative contact options.",
        API_KEY_EMAIL: `Email should be in valid format: \n` +
            `abc@abc.com`,
        API_KEY_APP_CODE: "A unique identifier or label for the application associated with the api key",
        API_KEY_NAME: "Specify a descriptive name or label for the API key to aid in identification and management within the system.",
        API_KEY_VALIDITY_DAYS: "API key will expire after the entered number of days",
        NUMBER_RANGE_START: "Determine the starting range for extension numbers, representing the lowest permissible value within the range of allowable extensions",
        NUMBER_RANGE_END: "Determine the ending range for extension numbers, representing the highest permissible value within the range of allowable extensions",
        PHONE_NUMBER: "Validate the phone number format, ensuring it consists of 10 digits, to maintain consistency and compatibility with the required numerical structure.",
        ENV_NAME: "Please enter the unique environment name",
        ENV_KEY: "Please enter the key that is unique to identify the environment",
        ENV_URL: "Please enter the gateway-ms URL for the environment. Example: http://hostname:1578/pmivr/api",
        DEPLOYMENT_ENV: "Please select the environment on which biller is saved for which number is to be assigned.",
        DYNAMIC_OPTION_COLLECTION: "Select list variables enable users to choose from a predetermined list of options. "
            + " This list will be presented to the caller, providing them with a range of choices to select from "
            + "during interaction.",
        SELECT_USER_OPTION_INPUT_TYPE: "To customize user options, select the type of option that best suits your needs",
        SINGLE_VOICE_FILE_USER_OPTION: "Select the audio file that will guide callers through the available options. Also, map your options with "
            + "your values.",
        COLLECTION_ARRAY_AS_USER_OPTIONS: "Your options will be the items in option list. When you choose one, you'll "
            + "get the value of that item from the option value list. In case option list and option value list are same, "
            + "then need not to configure the option value list, as in that case, value will be fetched from option list "
            + "as per selection.",
        ASSIGN_NEW_VOICE_FOR_USER_OPTION: "Assign a unique voice file to new option.",
        DELETE_CURRENT_OPTION: "Delete the current option",
        SAVE_CURRENT_OPTION: "Save the current option",
        EDIT_EXISTING_OPTION_VOICE_FILE: "Edit the voice file for existing option",
        CONFIGURE_VOICE_FILE: "Configure your voice files. If speech input enabled then variable prompt is disabled.",
        MULTIPLE_VOICE_FILE_USER_OPTION: "Assign a unique voice file to each option. "
            + "Also, map your options with your values.",
        EDIT_OPTION_MAPPINGS: "Click to add / edit the mapping of options with values",
        DYNAMIC_OPTION_CUSTOMIZE_VOICE_FILE: "Customize the voice files and their mapping to the options.If speech input enabled then variable prompt is disabled.",
        SELECT_LIST_VARIABLE: "Select the variable of type list",
        TTS: "Enter valid text to convert it into speech, Example: hello, welcome. Special characters such as '',$,@,% etc. not allowed",
        DRAFT: {
            NAME: "Enter the name of the draft version for the flow",
            COMMENTS: "Enter the description for the draft version for the flow"
        },
        USER: {
            EMAIL: `Email should be in valid format: \n` +
                `abc@abc.com`,
            PASSWORD: `Enter a strong password for the user: \n` +
                `Must have at least one lowercase letter (a-z), at least one uppercase letter (A-Z), at least one number (0-9), at least one special character (*,&,% etc.)`,
            FIRST_NAME: 'Enter the first name of the user',
            LAST_NAME: 'Enter the last name of the user',
            ROLE: 'Select appropriate role for the user, permissions to the user will be given according to the role.',
            STATUS: 'Select whether the user is active or inactive',
            CONFIRM_PASSWORD: "Password should match with confirm password"
        },
        VOICE_FILE_FOR_LIST_OPTIONS: `The possible option list values determine all possible values of the list and their associated voice files. ` +
            `For example, if the list includes [{opt1: voiceFile1}, {opt2: voiceFile2}], those files will be played.`,
        VOICE_FILES_FOR_COLLECTION: "Define the possible values of the collection variable, and the voice files associated with it. " +
            "For example: collArray = ['val1', 'val2', 'val3'], and voiceFiles = [{name: val1, voiceFile: Voice1}] ,then val1 will be replaced by Voice1 " +
            "during runtime",
        DRAFT_OPTION: {
            LABEL: "Enter the label for draft option, which will be shown in the dropdown on diagram page",
            ACCESS_TOKEN: "Generate access token from API keys page, and paste the token generated here.",
            URL: "Enter the URL for environment of target builder where the flow will be drafted.",
            PUBLISH_FLOW_OPTION: "If checked, consider option for publishing the flow. Otherwise, option will be considered for drafting the flow."
        },
        CALL_LOGS: {
            SEARCH_TEXT: "Enter the text to search based on request ID, step name, business code, flow name, app code, DNID, callerId",
            DNID: "Enter the DNID for AGI call to search",
            STEP_NAME: "Enter the step name to search in call logs (Example: greetings)",
            CALLER_ID_NUMBER: "Enter the caller ID number from which the AGI call has been made",
            SESSION_ID: "Enter the session ID of the AGI call",
            ENVIRONMENT: "Select the deployment environment on which AGI call has run.",
            START_DATE: "Enter the starting date to filter logs, in a valid format DD-MM-YYYY",
            END_DATE: "Enter the end date to filter logs, in a valid format DD-MM-YYYY",
            BUSINESS_CODE: "Enter the Business Code/TLA to search call logs"
        }
    },
    INPUT: {
        TTS_SPEED: "Enter the speed for TTS within range of 10 to 100",
        UPLOAD_VOICE_FILE: "Add and listen to voice files directly from your telephony (Asterisk) server with the handy playback feature",
        CONDITION_VARIABLE: "Information collected from the caller during the interaction. Select the variable you want to check.",
        CONDITION_STRING: "Enter text (Without quotes)",
        CONDITION_NUMBER: "Enter value",
        CONDITION_DATE: "Enter date in YYYY-MM-DD format",
        TRANSFER_NUMBER: "Specify the phone number or extension for a call transfer.",
        TRANSFER_NUMBER_EXTENSION: "Specify the phone extension for a call transfer.",
        USER_INPUT_REGEX_VALIDATION: "Specify the regex to define range to validate the user input" +
            "Example : Regex for input between 10-100 is ^(100|[1-9][0-9])$",
        BUSINESS_START_TIME: "Business Start Time",
        BUSINESS_END_TIME: "Business End Time",
        TRANSFER_CALL_START_TIME: "Start Time",
        TRANSFER_CALL_END_TIME: "End Time",
        HOLIDAYS_LIST: "Provide holiday dates in mm/dd/yyyy format for clarity",
        ZERO_OUT_VOICE_FILE: "Setup voice file for on-hold message during transfer",
        OUTSIDE_OF_BUSINESS_HOURS: "Designate voice file for calls outside business hours.",
        BASE_FILE_PATH: "Base File Path for voice file",
        TIMEOUT: "Enter the timeout in seconds",
        PAYMENT_AUTH_RECORDING: "Only one recording file can be there for payment authorization for one call, "
            + "checking this field will make this recording as payment auth recording and this recording will "
            + "be available on AD to download.",
        AUDIO_FORMAT: "Configure the format in which the recorded audio will be saved.",
        SAY_DATA_VARIABLE: "Enter the variable name from which you want the IVR to read data",
        SAY_DATA_COLLECTION: "Specify the name of the collection containing the data you want to prompt to the caller",
        SERVICE_IMPL_RESPONSE_VARIABLE: "Enter the response variable for service",
        SERVICE_IMPL_EXPRESSION: "Specify the expression to be implemented. Add $ to variable name, like $varName",
        API_URL: "Enter the URL of the external service you want to access.",
        API_QUERY_PARAMS: "Define any parameters required for the API request. These are the key-value pairs that provide additional information to the API",
        API_HEADERS: "Define any headers required for the API request. These are the key-value pairs that provide additional context about the request or response",
        API_PAYLOAD: "Input the data to be sent as part of the API request, typically used for POST requests.",
        API_METHOD: "Choose between POST or GET based on the API requirements.",
        AUTH_PAYLOAD: "Input the data to be sent as part of the AUTH request, typically used for POST requests.",
        AUTH_URL: "Enter the Auth URL for service",
        AUTH_RESPONSE_VARIABLE: "Enter the auth response variable for the service",
        AUTH_PARAMS_JSON_STRING: "It is used to encode and pass query parameters as part of a URL or an HTTP request",
        WIZARD_EXTENSION: "Extension for the new flow",
        API_KEY_EMAIL: "Email of the user who needs API key",
        API_KEY_APP_CODE: "Unique App code for which the API key is generated.",
        API_KEY_NAME: "Name or label for API key identification",
        API_KEY_VALIDITY_DAYS: "Duration (in days) for which the API key will be active",
        NUMBER_RANGE_START: "Define starting range for the number",
        NUMBER_RANGE_END: "Specify the highest extension number allowed",
        PHONE_NUMBER: "Ensure phone number is in valid format with 10 digits",
        TTS: "Input text statements or phrases for the IVR to convert into speech",
        OPTION_VARIABLE: "This configuration allows you to store the user's selected option in a designated variable",
        RETRY_COUNT: "Defines how many times a user can attempt a selection before moving on in the IVR flow. The default value is 3.",
        USER_INPUT_MIN_DIGITS: "Specify minimum allowed digits for user input or speech.",
        USER_INPUT_MAX_DIGITS: "Specify maximum allowed digits for user input or speech.",
        USER_INPUT_MIN_VALUE: "Please define the minimum value user can input or speak.",
        USER_INPUT_MAX_VALUE: "Please define the maximum value user can input or speak.",
        USER_INPUT_VARIABLE: "Allocate variable for storage of user-entered information.",
        USER_INPUT_RETRY_COUNT: "Determine the retry count for user input",
        USER_INPUT_TIMEOUT: "Set timeout for user input, in milliseconds",
        NO_SPEECH_INPUT_TIMEOUT: "Maximum time (in ms) for which speech input system will wait for the speech input from the user before it stops waiting.",
        MAX_SPEECH_TIMEOUT: "Maximum time (in ms), the system will wait for speech input, before considering the input as timed out or terminating the listening process.",
        USER_INPUT_PRECISION: "Enter the precision to consider after decimal, if you have to enter * with the input.",
        USER_INPUT_DATE_READBACK_PATTERN: "Select parts of date to readback, you can select from suggestions in the dropdown. " +
            "Each option can be selected once, and can not be repeated.",
        INVALID_VOICE_FILE: "When user input is incorrect, play a designated voice file to provide guidance.",
        INPUT_DATA_READBACK: "Set the IVR to read back the entered data to the user for confirmation",
        INPUT_DATA_READBACK_PATTERN: "Read user inputs following the specified pattern, pausing between sets. " +
            "For date formats like MMDDYYYY or MMYY, the system reads based on the pattern. Continuous reading for excess input length.",
        ALPHANUMERIC_PATTERN: "Define a specific pattern that the user's input must follow. "
            + "Example: NA (N -> Numeric, A -> Alpha) : It says first input will be number and second input will be alpha",
        INPUT_SEPARATOR: "Configure a separator character to separate the user input characters, Example: If you want to enter 123-45 , then you enter 123*45 , the '*' will be replaced with configured separator",
        INPUT_PREFIX: "Allow predefined input data as a prefix to user input",
        PROMPT_DATA_TYPE: "Select the type of variable, choosing from options. As per these selected options, the value will be prompted",
        DYNAMIC_OPTION_COLLECTION: "Designate variable for selecting options from a collection",
        FLOW_TYPE_API_URL: "Enter the url to get the list of flows",
        FLOW_DETAIL_API_URL: "Enter the url to get the JSON data for any flow",
        QUERY_PARAMS: "Enter query params to be appended in the API URL using '&'",
        URL_PARAMS: "Enter params to be appended in the API URL using '/'",
        DYNAMIC_OPTION_MAPPING_FIELD: "Enter the field name from the option list element to which the voice file will be mapped to.",
        BUSINESS_HOUR: "Select this checkbox to apply common business hours for all days.",
        SPEECH_VOICE: "Speech Voice",
        ACTION_KEY: "Action key for TTS",
        TTS_LANGUAGE: "TTS supported language"
    },
    // navigation link tooltips
    NAVIGATION_LINKS: {
        HOME: "Home: Search Client",
        FLOWS: "Client Flows",
        DIAGRAM: "Flow Diagram",
        AUDIO_FILES: "Audio Files",
        VARIABLES: "Variables",
        CHATBOT: "ChatBot: Coming Soon",
        WIZARD: "Wizard",
        VOICE_FILE_MANAGER: "Voice File Manager"
    },
    // flow flag
    FLOW_FLAG: {
        WORKING: "Working Flow Version",
        FAILED: "Failed Flow Version"
    }
};

// weekdays for displaying on builder for transfer control
export const WEEKDAYS = {
    INITIAL: ["S", "M", "T", "W", "T", "F", "S"],
    FULL_NAME: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
};
