import React from "react";
import PropTypes from "prop-types";

/**
 * Renders a progress bar with steps and icons.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.steps - Array of step objects [{ title, component }].
 * @param {number} props.currentStep - The index of the current step.
 * @param {Function} props.setCurrentStep - Function to update the current step.
 * @returns {JSX.Element} The progress bar component.
 */
const PmivrProgressBar = ({ steps, currentStep, setCurrentStep }) => {
    /**
     * Renders an SVG step icon based on the active state.
     * @param {boolean} isActive - Whether the step is active.
     * @param {boolean} isClickable - Whether the step is clickable.
     * @param {number} index - Step index.
     * @returns {JSX.Element} - The SVG element representing the step icon.
     */
    const renderStepIcon = (isActive, isClickable, index) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            role="img"
            aria-label={`Step ${index + 1} ${isActive ? "active" : "inactive"}`}
            className={isClickable ? "cursor-pointer" : ""}
            onClick={() => isClickable && setCurrentStep(index)}>
            {isActive ? (
                <>
                    <circle cx="12" cy="12" r="12" fill="rgba(6, 30, 81)" />
                    <circle cx="12" cy="12" r="5" fill="white" />
                </>
            ) : (
                <>
                    <circle cx="12" cy="12" r="11" fill="white" stroke="#B8C3E4" strokeWidth="2" />
                    <circle cx="12" cy="12" r="5" fill="#B8C3E4" />
                </>
            )}
        </svg>
    );

    return (
        <div className="position-relative py-3 mx-5 pmivr-progress-bar">
            {/* Progress Line */}
            <div className="position-relative mx-5 progress-line">
                <div style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}></div>
            </div>
            {/* Step Indicators */}
            <div className="d-flex justify-content-between align-items-center position-relative step-indicator">
                {steps.map((step, index) => (
                    <div key={index} className="text-center">
                        {/* Clickable Step Icon */}
                        <div className="d-flex justify-content-end align-items-center mx-5">
                            {renderStepIcon(index <= currentStep, index !== currentStep, index)}
                        </div>
                        {/* Step Title */}
                        <small className={`d-block mt-2 ${index <= currentStep ? "text-primary fw-bold" : "text-muted"}`}>
                            {step.title}
                        </small>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * PropTypes for PmivrProgressBar
 */
PmivrProgressBar.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            component: PropTypes.node.isRequired,
        })
    ).isRequired,
    currentStep: PropTypes.number.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
};

export default PmivrProgressBar;
