import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CSS_CLASSES } from "../../constants/css-classes";
import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { FLOW_STATUS, FLOW_TYPE_ID } from "../../constants/flow.js";
import { DNID_NUMBER_STATUS, UPDATE_STATE } from "../../constants/dnid";
import { FIELD_TYPES } from "../../constants/field-types";
import { APP_PAGES } from "../../constants/app-pages";
import { APP_CONFIG } from "../../config/config";

import PmivrSnackBar from "../../components/common/dialog/pmivr-snackbar";
import PmivrTooltip from "../../components/common/tooltip/pmivr-tooltip";
import PmivrAppLayout from "../../components/common/layouts/pmivr-app-layout.js";
import ConfigureDnid from "./components/configure-dnid.js";
import SetUpIVRQuestions from "./components/setup-ivr-questions.js";
import PmivrProgressBar from "../../components/common/progress-bar/pmivr-progress-bar.js";

import {
  updateDraft, updateSelectedFlowType, updateVersion, updateSelectedFlowTypeInformation,
  updateDeploymentEnv
} from "../../redux/actions/client.action";
import { updateChatFlowInfo } from "../../redux/actions/interactive-design.action";
import { updateVoiceFilePrefixInfo } from "../../redux/actions/voice-file.action";
import AppUtil from "../../util/app.util";

import WizardService from "../../services/wizard.service";
import ClientService from "../../services/client.service";
import UserService from "../../services/user.service";
import FlowService from "../../services/flow.service";
import ConfigService from "../../services/config.service.js";

/**
 * The wizard for configuring the client for IVR.
 * The client can answer the questions for configurations needed to be done for IVR.
 * @returns {React.Component} Html element containing questions with checkbox and radio buttons to render 
 */
const Wizard = () => {
  const location = useLocation();
  // get the value of disabled the wizard from query param
  const searchParams = new URLSearchParams(location.search);
  // navigating to pages
  const navigate = useNavigate();
  // updates the state in redux
  const dispatch = useDispatch();
  // using the open method from the snackbar component
  const snackbarRef = useRef();
  // getting latest state from redux
  let { businessCode, versionId, flowType, isVerifiedClient, selectedFlowTypeInfo, deploymentEnvironment } = useSelector(state => state.client);
  const { chatFlowId } = useSelector(state => state.interactiveDesign);
  // questions to be display in wizard screen
  const [questionnaire, setQuestionnaire] = useState([]);
  const [autoLayout, setAutoLayout] = useState(false);
  // list of dnid added by user and its details
  const [dnidInfo, setDnidInfo] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // tempWizardState for storing previous state to maintain wizards value on cancel button
  const [tempWizardState, setTempWizardState] = useState({ config: [], dnidInfo: [], savedDnidLength: 0 });
  // Cureent step to show in the progress bar
  const [currentStep, setCurrentStep] = useState(0);
  // Store the questions of category paymentus, transfer, basic 
  const [categoryQuestions, setCategoryQuestions] = useState({ payments: [], transfer: [], basic: [] });
  /**
   * showWarning: show the warning
   * isEdit: is the wizard can be edited
   * clientFlowExists: if coming from diagram page, then we get clientFlowExists as true, otherwise we get false
   * savedDnidLength: save the length of already saved dnids so as to maintain list of new dnid enterd on UI
   * selectedDnidRow: Info of dnid of the selected row in all the rows of dnid
   */
  const [uiState, setUiState] = useState({
    isEdit: false, showWarning: false, clientFlowExists: searchParams.get('clientFlowExists') === "true" || false,
    savedDnidLength: 0
  });

  // if businessCode name not found in the state, then read it from path url
  if (!businessCode) {
    businessCode = location.pathname.split("/").at(-1);
  }

  useEffect(() => {
    // check if token exists if not then redirect to login page.
    const currentUser = UserService.getCurrentUser();
    if (!currentUser?.token) {
      navigate(APP_PAGES.LOGIN);
    }
    // check the given businessCode is newly and load the wizard else show alert and redirect to home page
    checkBusinessCodeAndLoadWizard(businessCode);
    if (uiState?.clientFlowExists) {
      setDnidInfo(selectedFlowTypeInfo?.dnid);
      setUiState({ ...uiState, savedDnidLength: selectedFlowTypeInfo?.dnid?.length });
    }
  }, []);

  useEffect(() => {
    // Update the button's disabled state
    setIsButtonDisabled(isSaveWizardDisabled(dnidInfo));
  }, [dnidInfo]);

  /**
   * Effect hook that runs whenever `questionnaire` updates.
   * It categorizes the fetched questions into different state variables.
   */
  useEffect(() => {
    categorizeQuestions(questionnaire)
  }, [questionnaire]);

  /**
   * Segregates the given questions into different categories.
   * @param {Array} questions - Array of question objects to be categorized.
   */
  const categorizeQuestions = (questions) => {
    setCategoryQuestions(WizardService.segregateQuestions(questions));
  };


  /**
   * Check if save wizard and update wizard button to be disabled
   * @param {Array<{{dnid, lext,filledVars}}>} dnidInfo 
   * @returns 
   */
  const isSaveWizardDisabled = (dnidInfo) => {
    // Check if any dnid is empty or any lext is empty or a single space when showExt is true
    const hasInvalidEntry = dnidInfo?.some(item =>
      item.dnid === '' ||
      (item.showExt && (item.lext === '' || item.lext === ' '))
    );

    // Count occurrences of dnid where lext is empty
    const dnidCounts = dnidInfo?.reduce((acc, item) => {
      if (item.lext === '') {
        acc[item.dnid] = (acc[item.dnid] || 0) + 1;
      }
      return acc;
    }, {});
    const hasDuplicateDnidWithEmptyLext = Object?.values(dnidCounts)?.some(count => count > 1);

    return hasInvalidEntry || hasDuplicateDnidWithEmptyLext;
  };

  /**
   * Check if the current businessCode is new and load the wizard else show alert msg and redict to home page
   * @param {string} businessCode Name of businessCode
   */
  const checkBusinessCodeAndLoadWizard = async (businessCode) => {
    // user can directly open the wizard page but on UI no flow was selected
    // so if no flow is there,then redirect to home page 
    if (!selectedFlowTypeInfo?.flowName) {
      alert(MESSAGES.ERR.NO_FLOW_SELECTED);
      return navigate(APP_PAGES.HOME);
    }

    /**
      * Checking whether the deployment environments are configured or not.
      * If not configured, then run as normal, no need to verify for the environment on which businessCode is saved.
      * If configured, then giving preferance to the query param and if we found envKey in the query param, then save it in redux,
      * and verify client in that environment.
      * If envKey is not found in query param, then check the env in redux (saved on home page) and verify the client.
      * if envKey is not found in both queryParam and redux, then show error on the screen.
      * 
      * EnvKey will be set in the query param only when we straight hit the url of the page.
      * 
      * in redux, isVerifiedClient : true/false
      */

    try {
      const envKeyParam = searchParams.get('envKey');
      const isDeploymentEnvironmentExists = await ConfigService.isDeploymentEnvironmentExists();
      if (isDeploymentEnvironmentExists && AppUtil.isValueValid(envKeyParam)) {
        dispatch(updateDeploymentEnv({ deploymentEnvironment: envKeyParam }));
        // if user comes here directly through url, then verifying the client
        const clientVerificationResponse = await ClientService.getClientVerification(businessCode);
        // clientVerificationResponse: {data: true}
        isVerifiedClient = clientVerificationResponse?.data || false;
      } else {
        if (isDeploymentEnvironmentExists) {
          if (!AppUtil.isValueValid(deploymentEnvironment)) {
            alert(MESSAGES.ERR.DEPLOYMENT_ENVIRONMENT_NOT_FOUND);
            navigate(APP_PAGES.HOME);
            return;
          }
        }
        if (!isVerifiedClient) {
          // if we directly hit the url and verifyClient not found in redux, then verifying the client
          const clientVerificationResponse = await ClientService.getClientVerification(businessCode);
          // clientVerificationResponse: {data: true}
          isVerifiedClient = clientVerificationResponse?.data || false;
        }
      }
    } catch (err) {
      alert(MESSAGES.ERR.BUSINESS_CODE_NOT_EXISTS_ENVIRONMENT);
      navigate(APP_PAGES.HOME);
      return;
    }

    if (isVerifiedClient) {
      // adding the businessCode being currently searched in local storage for further use
      const basicFlowInfo = FlowService.getBasicFlowInfo();
      basicFlowInfo.businessCode = businessCode;
      FlowService.setBasicFlowInfo(basicFlowInfo);
      const filters = { flowName: selectedFlowTypeInfo.flowName, flowTypeId: selectedFlowTypeInfo.flowTypeId }
      // user may hit wizard page for existing businessCode so we neeed to check this
      //getting the flowInfo as per businessCode
      const flowInfo = await ClientService.getFlowInfo(businessCode, filters);
      // taking out draft docs info and published docs info from flowInfo cache
      const { draft, published } = flowInfo;
      // drafts docs or published docs found, it means that it is an existing businessCode (redirect to home page)
      // not found, it means that it is a new businessCode and we need to onboard it
      if ((draft?.length > 0 || published?.length > 0) && !uiState.clientFlowExists) {
        alert(MESSAGES.EXISTING_BUSINESS_CODE);
        navigate(APP_PAGES.HOME);
      } else {
        loadWizard();
      }
    } else {
      alert(MESSAGES.CONFIG_KEYS_NOT_FOUND);
      navigate(APP_PAGES.HOME);
    }
  };
  /**
   * Called when navigate to diagram page
   * @param {string} businessCode Name of businessCode
   * @param {string} docType Status of flow
   * @param {string} versionId Version id of the doc
   */
  const navigateToDiagram = async (businessCode, docType, versionId) => {
    navigate(`${APP_PAGES.DIAGRAM}/${businessCode}/${docType}/${versionId}?autoLayout=${autoLayout}`);
  };

  /**
   * Extract the question Id and it's value from questionaaire
   * @param {Array} questions Set of question in array form
   * @returns {Array} Return set of question id and it's value in list
   */
  function extractQuestionsValues(questions) {
    const questionsValues = [];
    questions.forEach((question) => {
      let childrenValues = [];
      // child question are present then get the value of child question
      if (question.children) {
        question.children.forEach((subQuestion) => {
          // in case of child question use recursion and add it's value to list( childrenValues )
          childrenValues = childrenValues.concat(
            extractQuestionsValues([subQuestion])
          );
        });
      }

      questionsValues.push({
        questionId: question.questionId,
        value: question.value,
        callVariableName: question.callVariableName,
        key: question.key,
        childQuestionId: question.childQuestionId,
        children: childrenValues,
        validation: question.validation
      });
    });
    return questionsValues;
  }

  /**
   * Validating the question values of the wizard
   * @param {Array} questionsValues Array of wizard questions with their values
   * @returns {Boolean} true, if validated successfully. Otherwise, return false
   */
  const validateQuestions = (questionsValues) => {
    let isValid = true;
    // Validating each and every question that is configured for validation
    for (const question of questionsValues) {
      if (question?.validation) {
        for (const validation of question.validation) {
          if (validation?.type === 'required') {
            isValid = AppUtil.isValueValid(question.value);
          }
          if (!isValid) {
            // question value is not valid, then break from inner loop
            break;
          }
        }
        if (!isValid) {
          // question value is not valid, then break from outer loop
          break;
        }
      }
    }
    return isValid;
  }

  /**
   * Saves the wizard configuration for businessCode
   * Upload the XML to server
   * @param {Array} questions
   */
  const saveWizard = async (questions) => {
    setUiState({ ...uiState, isEdit: true });
    try {
      // extract question id and selected values from questions
      const questionsValues = extractQuestionsValues(questions);
      const updatedDnidInfo = dnidInfo?.map(({ dnid, lext, transferChannelCode, transferNumber, transferNumberExtension, filledVars }) =>
        ({ dnid, lext, transferNumber, transferChannelCode, transferNumberExtension, filledVars }));
      const flowTypeInfo = {
        dnid: updatedDnidInfo,
        flowName: selectedFlowTypeInfo.flowName,
        flowTypeId: selectedFlowTypeInfo.flowTypeId, wizardConfig: questionsValues
      };
      // parent flow xml that is sent to the backend in case of making other flows as custom
      if (selectedFlowTypeInfo?.parentFlowXml) {
        flowTypeInfo.parentFlowXml = selectedFlowTypeInfo?.parentFlowXml;
      }
      // if redirectedXml is their then update the redux
      // redirected xml is xml coming from another env on saving wizard we update redux
      if (selectedFlowTypeInfo?.redirectedXml) {
        flowTypeInfo.redirectedXml = selectedFlowTypeInfo?.redirectedXml;
      }
      if (selectedFlowTypeInfo?.chatFlowId) {
        flowTypeInfo.chatFlowId = selectedFlowTypeInfo?.chatFlowId;
      }
      dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: flowTypeInfo }));
      // check at least one language is selected
      if (!validateQuestions(questionsValues)) {
        // opening the snackbar
        snackbarRef.current.open(MESSAGES.ATLEAST_SELECT_ONE_LANG);
        return;
      }
      // save Flow information
      await saveFlowInfo(questionsValues);
      // update redux state for voice file suffix configured by client
      getVoiceFilePrefix(questionsValues);

      // opening the snackbar
      snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
      // get the flow information from cache and redirect to diagram page
      const flowInformation = await ClientService.getFlowInfo(businessCode);
      // giving timeout to remain on the same screen for displaying message
      setTimeout(() => {
        navigateToDiagram(flowInformation.businessCode, flowInformation.status, flowInformation.docVersionId);
      }, APP_CONFIG.MESSAGE_TIMEOUT);

      if (chatFlowId) {
        dispatch(updateChatFlowInfo({ chatFlowId: "" }));
      }
    } catch (error) {
      setUiState({ ...uiState, isEdit: false });
      // opening the snackbar
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.WIZARD_SAVE);
      }
    }
  };

  /**
   * Get the voice file suffix to append in base path and set it to redux
   * @param {Array} wizardConfig Array of questions
   */
  const getVoiceFilePrefix = (wizardConfig) => {
    if (wizardConfig.length) {
      const voicePathData = wizardConfig[0]?.children;
      const voiceFilePrefixInfo = {};
      voicePathData.forEach((item) => {
        if (!item?.childQuestionId) {
          voiceFilePrefixInfo[item.key] = item.value;
        }
      });
      dispatch(updateVoiceFilePrefixInfo({ voiceFilePrefixObj: voiceFilePrefixInfo }));
    }
  }

  // save information of flow in S3 and update flow info cache
  async function saveFlowInfo(questionsValues) {
    let configureClientResponse;
    let docVersionId = uiState.clientFlowExists ? versionId : "";
    const status = uiState.clientFlowExists ? flowType : FLOW_STATUS.DRAFT;
    const flowInfo = {
      status: status, businessCode, parentVersionId: docVersionId, versionId: docVersionId,
      isDeleted: false, flowName: selectedFlowTypeInfo.flowName, flowTypeId: selectedFlowTypeInfo.flowTypeId
    };

    // In case of custom flow if it is created from any other flow, then append the parent flow xml in flowInfo
    const parentFlowXml = selectedFlowTypeInfo?.parentFlowXml;
    if (parentFlowXml) {
      flowInfo.xml = parentFlowXml;
    }
    // send client's setting in for configure client
    const updatedDnidInfo = dnidInfo?.map(({ dnid, lext, transferChannelCode, transferNumber, transferNumberExtension, filledVars }) => ({ dnid, lext, transferNumber, transferChannelCode, transferNumberExtension, filledVars }));
    const clientInfo = { dnid: updatedDnidInfo, questionsValues };
    if (!uiState.clientFlowExists) {
      // client is not already configured
      // so it will configure client in S3 and save flow information in database
      if (chatFlowId) {
        setAutoLayout(true);
        // create flowInfo for interactive design
        const flowInfo = {
          status: FLOW_STATUS.DRAFT, businessCode,
          parentVersionId: "", versionId: "", isDeleted: false,
          flowName: selectedFlowTypeInfo.flowName, flowTypeId: FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW,
          dnid: updatedDnidInfo, questionsValues, chatFlowId
        };
        // convert json to xml and configure the client
        const response = await FlowService.interactiveJsonToXml(flowInfo);
        docVersionId = response?.versionId;
      } else {
        configureClientResponse = await ClientService.configureClient(flowInfo, clientInfo);
        docVersionId = configureClientResponse?.versionId;
      }
    } else {
      // client is already configured and updating wizardConfig
      await ClientService.saveWizardConfig(businessCode, questionsValues);
    }

    dispatch(updateVersion({ versionId: docVersionId }));
    dispatch(updateSelectedFlowType({ flowType: status }));
    const basicFlowInfo = FlowService.getBasicFlowInfo();
    basicFlowInfo.docVersionId = docVersionId;
    basicFlowInfo.flowType = status;
    basicFlowInfo.flowName = selectedFlowTypeInfo.flowName;
    basicFlowInfo.flowTypeId = selectedFlowTypeInfo.flowTypeId;
    FlowService.setBasicFlowInfo(basicFlowInfo);

    // getting the flow information
    const flowInformation = await ClientService.getFlowInfo(businessCode);
    dispatch(updateDraft({ draft: flowInformation.draft }));
  }

  /**
   * Map the client wizard config values with the questions and its default values
   * Map the values with questions based on question Id.
   * @param {Array<{questionId, children, title, value, flowTypeId, childRenderOption}>} wizardQuestions 
   * @param {Array<{questionId, children, title, value, flowTypeId, childRenderOption}>} clientWizardConfig 
   */
  function setClientWizard(wizardQuestions, clientWizardConfig) {
    // Iterate over each item in the clientWizardConfig array
    clientWizardConfig.forEach(clientWizardConfigItem => {
      // Find the index of the corresponding item in the wizardQuestions array based on the questionId
      const index = wizardQuestions.findIndex(wizardQuest => wizardQuest.questionId === clientWizardConfigItem.questionId);
      if (index !== -1) {
        // If the item is found in the wizardQuestions array
        const wizardQuest = wizardQuestions[index];
        if (clientWizardConfigItem?.children?.length) {
          // If the second item has children
          clientWizardConfigItem.children.forEach((child, childIndex) => {
            // Iterate over each child
            if ('key' in child) {
              let firstChildIndex;
              // for editing the wizard and to show answers on UI with questions we have this child question id
              if ("childQuestionId" in child) {
                firstChildIndex = wizardQuest.children.findIndex(firstChild =>
                  firstChild.childQuestionId === child.childQuestionId && firstChild.key === child.key
                );
              } else {
                // If the child has a key property, find its index in the first item's children
                firstChildIndex = wizardQuest.children.findIndex(firstChild => firstChild.key === child.key);
              }
              if (firstChildIndex !== -1) {
                // If the child is found in the first item's children, merge its properties with the first item's child
                const firstChild = wizardQuest.children[firstChildIndex];
                Object.assign(firstChild, child);
              }
            } else {
              // If the child does not have a key property, assign its properties to the corresponding child 
              //in the first item's children
              if (childIndex < wizardQuest.children.length) {
                const firstChild = wizardQuest.children[childIndex];
                Object.assign(firstChild, child);
              }
            }
          });
        }
        // Remove the children property from the second item and assign its properties to the corresponding item
        // in the first array
        const { children, ...rest } = clientWizardConfigItem;
        Object.assign(wizardQuest, rest);
      }
    });
    // Return the updated first array
    return wizardQuestions;
  }

  /**
   * Get the wizard from db, saved while setuping the IVR for the client,
   * And then update the updated values.
   */
  async function loadWizard() {
    try {
      // get the questions, available dnid numbers and client wizard config
      const wizardP$ = [
        WizardService.getWizard(selectedFlowTypeInfo.flowTypeId),
        ClientService.getWizardConfig({ businessCode, flowName: selectedFlowTypeInfo?.flowName }),
        ClientService.getDnidNumberPool(DNID_NUMBER_STATUS.AVAILABLE)
      ];
      let wizardInfo = await Promise.all(wizardP$);
      // get question's value from default value and selected user values
      const wizardConfig = wizardInfo[1].length ? wizardInfo[1] : [];

      wizardInfo = formatQuestions(wizardInfo[0], wizardConfig);
      // update wizardInfo
      if (uiState.clientFlowExists) {
        // set the client wizard and display in disabled form
        const wizardDisplayQuestions = setClientWizard(wizardInfo, wizardConfig);
        setQuestionnaire(wizardDisplayQuestions);
        setTempWizardState((prev) => ({
          ...prev, config: AppUtil.deepClone(wizardDisplayQuestions), savedDnidLength: selectedFlowTypeInfo?.dnid?.length, dnidInfo: [...selectedFlowTypeInfo?.dnid]
        }));
        setUiState({ ...uiState, isEdit: true, savedDnidLength: selectedFlowTypeInfo?.dnid?.length });
      } else {
        setQuestionnaire(wizardInfo);
      }
    } catch (err) {
      // opening the snackbar
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.WIZARD_LOAD);
      }
      setUiState({ ...uiState, clientFlowExists: false });
    }
  }

  /**
   * Fetch question's value either from default or user selected values and return updated questionaire
   * @param {Array} questions Set of question in array form
   * @param {Array} wizardConfig Set of user selected question's value in array form
   * @returns {Array} Return updated questions
   */
  function formatQuestions(questions, wizardConfig) {
    // set question's value either from default or user selected values
    questions.forEach((question) => {
      question.value = question.default || "";
      // case where checkbox default value is []
      if (question.fieldType === FIELD_TYPES.CHECKBOX) {
        question.value = question.default ? question.default : [];
      }
      // question's value present in wizardConfig then update question's value
      // seperate function is made beacuse of sonarLint warning giving complexity error.
      updateQuestionsValue(wizardConfig, question);
    });
    return questions;
  }

  /**
   * Update the question in the wizard config
   * @param {Array} wizardConfig Set of user selected question's value in array form
   * @param {Object} question Questions that have been updated
   */
  function updateQuestionsValue(wizardConfig, question) {
    // question's value present in wizardConfig then update question's value
    const questionInfo = wizardConfig.find(
      (questionInfo) => questionInfo.questionId === question._id
    );
    if (questionInfo) {
      question.value = questionInfo.value;
      if (question.children) {
        question.children.forEach((subQuestion) => {
          const valueOfChilden = questionInfo.children
            ? questionInfo.children
            : [];
          formatQuestions([subQuestion], valueOfChilden);
        });
      }
    }
  }

  /**
   * Edit the wizard to make changes like language selection 
   * if one language selected and want another also
   */
  const updateWizard = async () => {
    try {
      // get the question values
      const questionsValues = extractQuestionsValues(questionnaire);
      const flowInfo = {
        businessCode, flowName: selectedFlowTypeInfo?.flowName,
        flowTypeId: selectedFlowTypeInfo?.flowTypeId, wizardConfig: questionsValues
      };
      // update the wizard
      const updatedDnidInfo = dnidInfo?.map(({ dnid, lext, transferChannelCode, transferNumber, transferNumberExtension, filledVars }) => ({ dnid, lext, transferNumber, transferNumberExtension, transferChannelCode, filledVars }));
      // send only those dnids who are newly added to update the status in number pool
      const oldDnidInfo = updatedDnidInfo.slice(0, uiState.savedDnidLength);
      const newDnidInfo = updatedDnidInfo.slice(uiState?.savedDnidLength);
      const dnidInformation = { oldDnidInfo, newDnidInfo };
      // update the wizard config and get the updated wizard values since we do tts and upload file to repo for transfer files.
      const updatedQuestionValues = await ClientService.updateWizardConfig(flowInfo, dnidInformation);
      if (updatedQuestionValues?.data) {
        // update wizard on UI for filePath to be visible
        const updatedQuestionnaire = setClientWizard(questionnaire, updatedQuestionValues?.data);
        setQuestionnaire(updatedQuestionnaire);
        // update the wizard in redux also
        const newFlowInformation = { ...selectedFlowTypeInfo, wizardConfig: updatedQuestionValues?.data, dnid: updatedDnidInfo };
        dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: newFlowInformation }));
        snackbarRef.current.open(MESSAGES.WIZARD_UPDATE);
        setUiState({ ...uiState, isEdit: true, savedDnidLength: dnidInfo?.length });
        setTempWizardState({ dnidInfo, savedDnidLength: dnidInfo?.length, config: AppUtil.deepClone(updatedQuestionnaire) });
        getVoiceFilePrefix(questionsValues);
      }
    } catch (err) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.WIZARD_UPDATE_ERR);
      }
    }
  }

  /**
   * By default wizard will be disabled so on button click we enable the wizard
   * so that user can make changes and on cancel it will be again disabled
   * @param {string} action Action to check if edit or cancel the wizard
   */
  const handleEditCancelToggle = (action) => {
    if (action === 'cancel') {
      setDnidInfo(tempWizardState?.dnidInfo);
      setQuestionnaire(AppUtil.deepClone(tempWizardState?.config));
      setUiState({ ...uiState, isEdit: !uiState.isEdit, savedDnidLength: tempWizardState?.savedDnidLength });
    } else {
      setUiState({ ...uiState, isEdit: !uiState.isEdit });
    }
  }

  /**
   * Function to update the Dnid info questionnaire and uiState, 
   * this function is called from the child compoent setup-ivr-question and onfigure-dnid
   * component, so that we don't have to pass the unnessasry props.
   * @param {object} updatedValueInfo - Object containing values of updatd state contains 
   * values for different state
   */
  const onUpdate = (updatedValuesObj = {}) => {
    const { updatedDnidInfo, updatedUiState, questions, flag } = updatedValuesObj;
    if (flag === UPDATE_STATE.SET_DNID_INFO) {
      setDnidInfo(updatedDnidInfo);
    }
    if (flag === UPDATE_STATE.SET_QUESTIONNAIRE) {
      setQuestionnaire(questions);
    }
    setUiState({ ...uiState, ...updatedUiState });
  }

  // Define the steps for the wizard, conditionally rendering components(configure Dnid and setup-ivr-queations) based on question availability
  const steps = [
    {
      title: "DNID",
      component: <ConfigureDnid onUpdate={onUpdate} dnidInfo={dnidInfo} isEdit={uiState.isEdit} questionnaire={questionnaire} />
    },
    categoryQuestions.basic.length && {
      title: "Basic",
      component: <SetUpIVRQuestions onUpdate={onUpdate} isEdit={uiState.isEdit} updatedQuestion={categoryQuestions.basic}
        questionnaire={questionnaire} />
    },
    categoryQuestions.payments.length && {
      title: "Payments",
      component: <SetUpIVRQuestions onUpdate={onUpdate} isEdit={uiState.isEdit} updatedQuestion={categoryQuestions.payments}
        questionnaire={questionnaire} />
    },
    categoryQuestions.transfer.length && {
      title: "Transfer Call",
      component: <SetUpIVRQuestions onUpdate={onUpdate} isEdit={uiState.isEdit} updatedQuestion={categoryQuestions.transfer}
        questionnaire={questionnaire} />
    },
  ].filter(Boolean);

  return (
    <>
      <PmivrAppLayout showLeftBar={uiState.clientFlowExists} showFooter={!uiState.clientFlowExists}>
        <PmivrSnackBar ref={snackbarRef} />
        <div className="pmivr-container">
          <div className="row border-bottom  pb-3 pt-3 ">
            <div className="col-lg-6">
              <div className={uiState.clientFlowExists
                ? `px-3 pmivr-breadcrumb-list ${CSS_CLASSES.HIDE_DISPLAY}`
                : `px-3 pmivr-breadcrumb-list ${CSS_CLASSES.INLINE_DISPLAY}`}>
                {deploymentEnvironment ? `${deploymentEnvironment} : ` : ``}{selectedFlowTypeInfo.flowName} : <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.HOME}>
                  <Link to={`/home`}>Home</Link></PmivrTooltip>/{businessCode}/wizard/
              </div>
              <div className={uiState.clientFlowExists
                ? `px-3 pmivr-breadcrumb-list ${CSS_CLASSES.INLINE_DISPLAY}`
                : `px-3 pmivr-breadcrumb-list ${CSS_CLASSES.HIDE_DISPLAY}`}>
                {deploymentEnvironment ? `${deploymentEnvironment} : ` : ``}{selectedFlowTypeInfo.flowName} : <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.HOME}>
                  <Link to={`/home`}>Home</Link></PmivrTooltip>/
                <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.FLOWS}>
                  <Link to={`${APP_PAGES.CLIENT_FLOWS}/${businessCode}`}>{businessCode}</Link>
                </PmivrTooltip>/
                <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.DIAGRAM}>
                  <Link to={`/diagram/${businessCode}/${flowType}/${versionId}`}>
                    {flowType}
                  </Link>
                </PmivrTooltip>
                /wizard/
              </div>
            </div>
          </div>

          <div className="pmivr-wizard-data">
            <div>
            </div>
            <div className="info-box d-flex align-items-center mx-4">
              {/* businessCode */}
              <label className="label">BUSINESS CODE / TLA | </label>
              <label className="label-value px-2">
                {businessCode}
              </label>
              {(uiState?.clientFlowExists && uiState.isEdit) &&
                <div className="ms-auto">
                  <PmivrTooltip message={TOOLTIP.UPDATE_WIZARD}>
                    <button disabled={!uiState?.isEdit}
                      className="pmivr-btn-app"
                      onClick={() => handleEditCancelToggle('edit')}>Edit</button>
                  </PmivrTooltip>
                </div>
              }
              {uiState?.clientFlowExists && !uiState.isEdit && (
                <div className="ms-auto">
                  <button className="pmivr-btn-cancel me-2"
                    onClick={() => handleEditCancelToggle('cancel')} >
                    Cancel
                  </button>
                  <button className="pmivr-btn-app"
                    disabled={isButtonDisabled}
                    onClick={() => updateWizard()} >
                    Save
                  </button>
                </div>
              )}
              {/* Submit Button */}
              {!uiState.clientFlowExists && !uiState.isEdit && (
                <div className="ms-auto">
                  <button className="pmivr-btn-app"
                    disabled={(uiState.showWarning) || isButtonDisabled || !dnidInfo.length}
                    onClick={() => saveWizard(questionnaire)} id="wizard-submit">
                    Submit
                  </button>
                </div>
              )}
            </div>
            {/* Progress Bar */}
            <PmivrProgressBar steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
            {/* Step Content */}
            <div className="step-content shadow-sm mx-2">{steps[currentStep]?.component}</div>
            <div className="pt-3 d-flex justify-content-end mx-3 position-relative" style={{ zIndex: "1" }}>
              <button type="button" className="pmivr-btn-cancel me-2" disabled={currentStep === 0}
                onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 0))} >
                Previous
              </button>
              <button type="button" className="pmivr-btn-app" disabled={currentStep === steps.length - 1}
                onClick={() => setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))} >
                Next
              </button>
            </div>
          </div>
        </div >
      </PmivrAppLayout >
    </>
  );
};

export default Wizard;