import PropTypes from 'prop-types';

import PmivrTooltip, { TOOLTIP_POSITIONS } from '../../../../components/common/tooltip/pmivr-tooltip';

import WizardService from '../../../../services/wizard.service';

/**
 * Show dnid values with extension and variables configured on the client flows page
 * @param {Object} props props data from parent component
 */
const ShowDnidModal = ({ flowInfo }) => {
    /**
     * Get transfer numbers for a given language
     * @param {{transferNumber,dnid}} dnidInfo 
     * @param {string} lang 
     * @returns {Array<{string}} array of transfer numbers based on languages
     */
    const getTransferNumbers = (dnidInfo, lang) => {
        const transferNumbers =
            typeof dnidInfo?.transferNumber === "object"
                ? dnidInfo?.transferNumber[lang] || "-"
                : dnidInfo?.transferNumber || "-";

        return Array.isArray(transferNumbers) ? transferNumbers : [transferNumbers];
    };

    return (
        <>
            <div className="row pb-2" id="info-header">
                <div className={"col-md-12"}>
                    <div className={`card pmivr-card p-4`}>
                        <table className="table voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
                            <thead>
                                <tr>
                                    <th width="20%" className="text-center">
                                        DNID
                                    </th>
                                    <th width="10%" className="text-center">
                                        LEXT
                                    </th>
                                    <th width="20%" className="text-center">
                                        Transfer Code
                                    </th>
                                    <th width="20%" className="text-center">
                                        Transfer Number
                                    </th>
                                    <th width="20%" className="text-center">
                                        VARIABLE
                                    </th>
                                    <th width="20%" className="text-center">
                                        VALUE
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="pmivr-scroll">
                                {flowInfo?.dnid?.length > 0 &&
                                    flowInfo.dnid.map((dnidInfo, index) => (
                                        <tr key={index}>
                                            <td width="20%">{dnidInfo?.dnid}</td>
                                            <td width="10%">{dnidInfo?.lext ? dnidInfo.lext : "-"}</td>
                                            <td width="20%">{dnidInfo?.transferChannelCode ? dnidInfo.transferChannelCode : "-"}</td>
                                            <td width="20%">
                                                {(() => {
                                                    // Get all languages from wizard config
                                                    const languages = WizardService.getLanguagesFromWizard(flowInfo?.wizardConfig || []);
                                                    // Get all transfer numbers for normal display
                                                    const transferNumbers = languages?.flatMap((lang) =>
                                                        getTransferNumbers(dnidInfo, lang)).filter((num) => num !== "-");
                                                    return (
                                                        <PmivrTooltip
                                                            position={TOOLTIP_POSITIONS.RIGHT}
                                                            popoverContent={
                                                                <ul className="list-unstyled m-0">
                                                                    {languages?.map((lang) => (
                                                                        <li key={lang}>
                                                                            <strong>{lang.toUpperCase()}:</strong> {
                                                                                getTransferNumbers(dnidInfo, lang).join(", ")
                                                                            }
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            }
                                                        >
                                                            <span>{transferNumbers.length > 0 ? transferNumbers.join(", ") : "-"}</span>
                                                        </PmivrTooltip>
                                                    );
                                                })()}
                                            </td>
                                            {dnidInfo?.filledVars?.length > 0 ? (
                                                dnidInfo.filledVars.map((variableInfo, varIndex) => (
                                                    <>
                                                        <td width="20%">{variableInfo?.variable}</td>
                                                        <td width="20%">{variableInfo?.value}</td>
                                                    </>
                                                ))
                                            ) : (
                                                <>
                                                    <td width="20%">-</td>
                                                    <td width="20%">-</td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
};

// Types of props passed in the component
ShowDnidModal.propTypes = {
    flowInfo: PropTypes.object
}

export default ShowDnidModal;