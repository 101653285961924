import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { APP_PAGES } from '../../constants/app-pages';
import { MESSAGES } from '../../constants/messages';

import VoiceFileList from './components/voice-file-list';
import ManageVoiceFile from './components/manage-voice-files';
import PmivrSnackBar from '../../components/common/dialog/pmivr-snackbar';

import VoiceFileManagerService from '../../services/voice-file-manager.service';

/**
 * VoiceFileManager Component
 * 
 * This component manages voice file settings, including fetching, displaying, 
 * and updating voice file details. It uses various states to manage the UI and data.
 */
const VoiceFileManager = () => {
    // for snackbar opening and closing
    const snackbarRef = useRef();
    // to access query params from url
    const location = useLocation();
    // getting the search params from url
    const searchQueryParams = new URLSearchParams(location.search);
    // extracting businessCode and environment from query params of the url
    const businessCode = searchQueryParams.get('businessCode');
    const environment = searchQueryParams.get('environment');
    // State to manage the visibility of the voice file settings menu and store the list of voice files.
    const [uiState, setUiState] = useState({ showVoiceFileSettingMenu: false, voiceFiles: [] });
    // State to store the title of the current voice file action.
    const [voiceFileActionTitle, setVoiceFileActionTitle] = useState("");
    // State to store details about the selected action and file.
    const [selectedDetails, setSelectedDetails] = useState({ actionKey: '', filePath: '', languageCode: '', index: '' });
    // State to track the current action key for the selected voice file.
    const [actionKey, setActionKey] = useState(selectedDetails.actionKey || '');
    // State to track the file path of the selected voice file.
    const [filePath, setFilePath] = useState(selectedDetails.filePath || '');
    // State to track language of the selected voice file
    const [languageCode, setLanguageCode] = useState(selectedDetails.languageCode || '');
    // State to store the list of all available voice files.
    const [voiceFileList, setVoiceFileList] = useState([]);
    // Information about the system voice file (file path,language)
    const [voiceFileInfo, setVoiceFileInfo] = useState({
        filePath: "",
        ttsText: "",
        selectedGender: "",
        playSpeed: "",
        fileSize: "",
        voiceFileType: ""
    });
    // action type which can be either save or edit of voice files
    const [actionType, setActionType] = useState("");
    // variable to store list of voice files which are added or edited currently
    const [editedOrAddedVoiceFiles, setEditedOrAddedVoiceFiles] = useState([]);

    /**
     * Effect to fetch voice file details when the component is mounted.
     */
    useEffect(() => {
        getVoiceFileDetails();
    }, []);

    /**
     * Fetches voice file details from the server using the VoiceFileManagerService.
     * Updates the state with the retrieved list of voice files.
     * 
     * @async
     * @function getVoiceFileDetails
     * @returns {Promise<void>}
     */
    const getVoiceFileDetails = async () => {
        const response = await VoiceFileManagerService.getMessageConfigByAppCodes(businessCode, "inbound-agi-server", environment);
        setVoiceFileList(response);
    }

    /**
     * Handles the save functionality when adding or updating a voice file.
     * 
     * This function performs the following actions:
     * - If `actionType` is 'add', it adds a new voice file entry to the `voiceFileList` array.
     * - If `actionType` is 'edit', it updates the existing voice file entry at the specified index in `voiceFileList`.
     * - After the save operation, it updates the `editedOrAddedVoiceFiles` list with the new/updated entry.
     * - It resets the `selectedDetails` and shows a success message using the snackbar.
     * - Finally, it closes the voice file setting menu after a successful save.
     */
    const handleSave = async () => {
        try {
            if (actionType === 'add') {
                const updatedData = { actionKey, actionMessage: filePath, languageCode: languageCode || "en", appCode: "inbound-agi-server" };
                // Check if the actionKey and languageCode combination already exists
                const isDuplicate = voiceFileList.some(
                    (item) => item.actionKey === updatedData.actionKey && item.languageCode === updatedData.languageCode
                );
                if (isDuplicate) {
                    if (snackbarRef?.current) {
                        snackbarRef.current.open("This already exists in our records. Please edit the existing entry instead !");
                        return;
                    }
                    return;
                } else {
                    const updatedFiles = [...voiceFileList, updatedData];
                    setVoiceFileList(updatedFiles);
                }
            } else {
                const updatedData = { actionKey, actionMessage: filePath, languageCode };
                const updatedFiles = voiceFileList.map((file, i) => {
                    if (i === selectedDetails.index) {
                        // Update the item at the specific index
                        return { ...file, ...updatedData };
                    }
                    return file;
                });
                setVoiceFileList(updatedFiles);
            }
            setEditedOrAddedVoiceFiles([...editedOrAddedVoiceFiles, { actionKey, actionMessage: filePath, lang: languageCode || "en", appCode: "inbound-agi-server" }]);
            setSelectedDetails({ actionKey: "", filePath: "", languageCode: "", index: "" });
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
            }
            // close the edit/save menu after successfully updating
            setTimeout(() => {
                setUiState({ ...uiState, showVoiceFileSettingMenu: false });
            }, 1000);
        } catch (error) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Handles the UI state for showing or hiding the voice file settings menu.
     * This function updates the UI state to show or hide the menu based on the 
     * provided `showSetting` boolean value.
     * @function handleUiState
     * @param {boolean} showSetting - A boolean value to indicate whether to show the settings menu or not.
     * @returns {void}
     */
    const handleUiState = (showSetting) => {
        setUiState({ ...uiState, showVoiceFileSettingMenu: showSetting });
    }

    /**
     * Handles publishing the edited or added voice files by updating the message configurations in oracle
     */
    const handlePublish = async () => {
        try {
            const response = await VoiceFileManagerService.updateMessageConfigs(editedOrAddedVoiceFiles, businessCode, environment);
            if (response?.success) {
                if (snackbarRef?.current) {
                    setEditedOrAddedVoiceFiles([]);
                    getVoiceFileDetails();
                    snackbarRef.current.open("Published Voice Files Successfully");
                }
            }
        }
        catch (err) {
            if (snackbarRef?.current) {
                snackbarRef?.current?.open("Unable to publish due to a technical issue. Please contact the administrator for support.");
            }
        }
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="row pt-1">
                <div className="row border-bottom pb-3 pt-3 ">
                    <div className="col-lg-6">
                        <div className="px-3 pmivr-breadcrumb-list">
                            <Link to={APP_PAGES.HOME}>Home</Link> / Voice File Manager
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className={`col-md-${uiState.showVoiceFileSettingMenu ? '8' : '12'} pt-2`} >
                    <div className='row '>
                        <div className="col-md-6 pmivr-breadcrumb-list pb-2">
                            <h4> <Link to={APP_PAGES.SETTINGS}><i class="bi bi-arrow-left arrow-left"></i></Link>  Voice File Manager</h4>
                        </div>
                        <div className='col-md-6 d-flex justify-content-end pe-3'>
                            <button className='pmivr-btn-secondary' onClick={handlePublish}>Publish</button>
                        </div>
                    </div>
                    <VoiceFileList setUiState={setUiState} uiState={uiState} handleUiState={handleUiState} setVoiceFileActionTitle={setVoiceFileActionTitle}
                        setSelectedDetails={setSelectedDetails} voiceFileList={voiceFileList} setVoiceFileList={setVoiceFileList}
                        setFilePath={setFilePath} setVoiceFileInfo={setVoiceFileInfo} businessCode={businessCode}
                        environment={environment} setActionType={setActionType} />
                </div>
                {uiState.showVoiceFileSettingMenu &&
                    <div className={`col-md-4 border-start ps-0`}>
                        <ManageVoiceFile voiceFileActionTitle={voiceFileActionTitle} selectedDetails={selectedDetails}
                            setSelectedDetails={setSelectedDetails} actionKey={actionKey} setActionKey={setActionKey}
                            filePath={filePath} setFilePath={setFilePath} languageCode={languageCode} setLanguageCode={setLanguageCode}
                            voiceFileInfo={voiceFileInfo} />
                        <div className='d-flex justify-content-end me-3'>
                            <button type="button" className="pmivr-btn-secondary me-1" onClick={() => handleUiState(false)}>
                                Cancel
                            </button>
                            <button type="submit" className="pmivr-btn-app me-1" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                }
            </div >
        </>
    );
}

export default VoiceFileManager;
