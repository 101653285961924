import React, { useEffect, useRef, useState, } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TASK_ICONS } from "../../../constants/css-classes";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { DNID_NUMBER_STATUS, UPDATE_STATE } from "../../../constants/dnid";
import PmivrTooltip from "../../../components/common/tooltip/pmivr-tooltip";
import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog";
import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar";
import DnidVariablesModal from "./dnid-variables-modal";
import ClientService from "../../../services/client.service";
import WizardService from "../../../services/wizard.service";

/**
 * This component is responsible for displaying and configuring DNID settings in the IVR flow.
 * It allows users to:
 * - View the list of DNIDs.
 * - Add, select, and remove DNIDs.
 * - Configure DNID-related settings e.g.:- lext, transfer number, variables.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.onUpdate - Function to update the state, based on the flag it will 
 * update the required state in parent component (wizard.js)
 * @param {Array} props.dnidInfo - List of DNID rows contains the all the information about DNID
 *  e.g.:- lext, transfer number, variables ect .
 * @param {boolean} props.isEdit.- Flag to indicate if we can edit the form based on the parent component 
 * also helps to restore previous state when user click on the cancel button before saving the changes
 * @param {Array<{ children: Array<Object>, options: Array<Object>, validation: Array<Object>, flowTypeId: Array<String>, 
 * title: String, questionId:String, category: String, fieldType: String, _id: String
 * }>} props.questionnaire - array of all the questions helping to set the different transfer number for 
 * different language while configuring the DNID
 * @returns {JSX.Element} The rendered component.
 */
const ConfigureDnid = ({ onUpdate, dnidInfo, isEdit, questionnaire }) => {
    // getting latest state from redux
    const { selectedFlowTypeInfo } = useSelector(state => state.client);
    const location = useLocation();
    // get the value of disabled the wizard from query param
    const searchParams = new URLSearchParams(location.search);
    // to store numbers
    const [numberPool, setNumberPool] = useState([]);
    /**
     * clientFlowExists: if coming from diagram page, then we get clientFlowExists as true, otherwise we get false
     * showWarning: show the warning
     * savedDnidLength: save the length of already saved dnids so as to maintain list of new dnid enterd on UI
     * selectedDnidRow: Info of dnid of the selected row in all the rows of dnid
     * showDnidValuesDialog: flag to show when the Dnid variables modal will be visible to configure the lext, transfer number etc
     * enableExt: help to check to the extension validity
     * selectedDnidIndex: index of the selected dnid row so that we can correctly map the dnid values 
     */
    const [uiState, setUiState] = useState({
        clientFlowExists: searchParams.get('clientFlowExists') === "true" || false, showWarning: false, savedDnidLength: 0,
        showDnidValuesDialog: false, selectedDnidRow: null, enableExt: false, selectedDnidIndex: 0, shouldAutoScroll: false
    });
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // Create a ref to store the newly added row element.
    const newRowRef = useRef(null);

    /**
     * Fetches the DNID number pool and updates the state.
     * This function:
     * - Calls the `ClientService.getDnidNumberPool` API to fetch available DNID numbers.
     * - Updates the `numberPoolList` state with the retrieved data.
     * - Handles errors by displaying an error message in the snackbar.
     * @async
     * @function fetchNumberPool
     * @returns {Promise<void>} - A promise that resolves when the data is fetched and state is updated.
     */
    useEffect(() => {
        const fetchNumberPool = async () => {
            try {
                const response = await ClientService.getDnidNumberPool(DNID_NUMBER_STATUS.AVAILABLE);
                setNumberPoolList(response?.data);
                setUiState({ ...uiState, savedDnidLength: selectedFlowTypeInfo?.dnid?.length || 0 })
            } catch (err) {
                // Opening the snackbar
                if (snackbarRef?.current) {
                    snackbarRef.current.open(MESSAGES.ERR.NUMBER_POOL_FETCH_ERR);
                }
            }
        };
        fetchNumberPool(); // Call the async function inside useEffect
    }, []); // Dependency array ensures it runs only once

    /**
     * Hook to auto scroll the dnid table when new row is added
     */
    useEffect(() => {
        // Check if there's at least one row and the ref is set
        if (uiState.shouldAutoScroll && dnidInfo.length && newRowRef.current) {
            newRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // Reset the flag after scrolling to prevent the autoScroll when we delete any row
            setUiState({ ...uiState, shouldAutoScroll: false })
        }
    }, [dnidInfo]);

    /**
     * Update number pool in state and handle uiState
     * @param {Array} numberPoolList 
     */
    const setNumberPoolList = (numberPoolList) => {
        // if dnid is not present show warning
        if (!numberPoolList.length) {
            onUpdate({ updatedUiState: { showWarning: true } });
            setUiState({ ...uiState, showWarning: true });
        }
        setNumberPool(numberPoolList);
    }

    //   Add new dnid row to configure new dnid and its variables
    const addDnidRow = () => {
        onUpdate({ updatedDnidInfo: [...dnidInfo, { dnid: '', transferChannelCode: "", transferNumber: "", showExt: false }], flag: UPDATE_STATE.SET_DNID_INFO });
        // Set the flag indicating a row addition
        setUiState({ ...uiState, shouldAutoScroll: true });
    };

    /**
     * Select the dnid number
     * It sets the state value for extension numbers and error messages
     * @param {number} dnid phnNumber selected 
     */
    const handleSelectDnid = (dnid, dnidIndex) => {
        if (dnid !== "") {
            // save the numberPool info from array using index
            // get the index of dnid selected
            const index = numberPool.findIndex(obj => obj.phoneNumber === dnid);
            const updatedRows = [...dnidInfo];
            if (numberPool[index].rangeStart || numberPool[index]?.lext) {
                updatedRows[dnidIndex].showExt = true;
            } else {
                updatedRows[dnidIndex].showExt = false;
            }
            updatedRows[dnidIndex].lext = "";
            updatedRows[dnidIndex].dnid = dnid;
            updatedRows[dnidIndex].transferChannelCode = "";
            updatedRows[dnidIndex].transferNumber = "";
            onUpdate({ updatedDnidInfo: updatedRows, flag: UPDATE_STATE.SET_DNID_INFO });
        } else {
            const updatedRows = [...dnidInfo];
            updatedRows[dnidIndex] = { dnid: '', lext: '', transferChannelCode: "", transferNumber: "", showExt: false };
            onUpdate({ updatedDnidInfo: updatedRows, flag: UPDATE_STATE.SET_DNID_INFO })
        }
    }

    /**
     * Remove the dnid row on delete button
     * @param {number} index index of the row in dnid rows
     */
    const handleRemoveDnid = (index) => {
        const updatedRows = [...dnidInfo];
        updatedRows.splice(index, 1);
        onUpdate({ updatedDnidInfo: updatedRows, flag: UPDATE_STATE.SET_DNID_INFO })
    };

    /**
     * It updates the dnid rows array with the new values
     * @param {{dnidRowValues, newDnidValues, lext, dnidIndex}} newDnidRowInfo new info of the dnid to update
     */
    const updateDnidRowValues = (newDnidRowInfo) => {
        const { dnidRowValues, newDnidValues, lext, dnidIndex, transferChannelCode, transferNumber, transferNumberExtension } = newDnidRowInfo;
        const updatedDnidRows = dnidInfo?.map((row, index) => {
            if (row.dnid === newDnidValues.dnid && row.lext === newDnidValues.lext && index === dnidIndex) {
                return {
                    ...row, filledVars: dnidRowValues, lext, transferChannelCode, transferNumber, transferNumberExtension
                };
            }
            return row;
        });
        //  function to update the dnidInfo in the parent component so that it gets the latest
        //  value whiling saving the final change
        onUpdate({ updatedDnidInfo: updatedDnidRows, flag: UPDATE_STATE.SET_DNID_INFO })
    }

    /**
     * Handles the opening of dialog with values of selected row
     * @param {*} dnidInfo dnidInfo of the selected row to show in dialog 
     * @param {*} dnidIndex index of the selected row in dnid rows
     */
    const handleDialogOpen = (currDnidInfo, dnidIndex) => {
        let enableExt = false;
        if (currDnidInfo.dnid) {
            const index = numberPool.findIndex(obj => obj.phoneNumber === currDnidInfo.dnid);
            const updatedRows = dnidInfo?.map(row => ({ ...row }));
            if (index !== -1 && (numberPool[index].rangeStart || numberPool[index]?.lext)) {
                updatedRows[dnidIndex].showExt = true;
                // Create a copy of currDnidInfo if it is not extensible
                if (!Object.isExtensible(currDnidInfo)) {
                    currDnidInfo = { ...currDnidInfo };
                }
                currDnidInfo["showExt"] = true;
                if (dnidIndex > uiState.savedDnidLength - 1) {
                    enableExt = true;
                }
            } else {
                updatedRows[dnidIndex].showExt = false;
            }
            onUpdate({ updatedDnidInfo: updatedRows, flag: UPDATE_STATE.SET_DNID_INFO })
            setUiState({ ...uiState, showDnidValuesDialog: true, selectedDnidRow: currDnidInfo, selectedDnidIndex: dnidIndex, enableExt })
        } else {
            setUiState({ ...uiState, selectedDnidRow: null, selectedDnidIndex: dnidIndex });
            snackbarRef.current.open(MESSAGES.DNID_INFO.SELECT_DNID);
        }
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div>
                {uiState.showWarning ? <h6 className="warning">{MESSAGES.DNID_UNAVAILABLE}</h6> : <></>}
            </div>
            <PmivrDialog showDialog={uiState.showDnidValuesDialog} title={"Add values for DNID"} cssClass={"dnid-modal"}
                closeDialog={() => setUiState({ ...uiState, showDnidValuesDialog: false })}
                message={<DnidVariablesModal updateDnidRowValues={updateDnidRowValues} dnidNumbersList={dnidInfo} numberPool={numberPool}
                    closeAction={() => setUiState({ ...uiState, showDnidValuesDialog: false })} dnidRowInfo={uiState.selectedDnidRow}
                    clientFlowExists={uiState.clientFlowExists} enableExt={uiState.enableExt} dnidIndex={uiState.selectedDnidIndex}
                    languages={WizardService.getLanguagesFromWizard(questionnaire)}
                />}
                footer={<></>} />
            {/* Header Section */}
            <div className="d-flex justify-content-start align-items-center mx-3 py-1">
                <h6 className="pmivr-title mt-1">Configure DNID for the Flow</h6>
                <button type="button" className="pmivr-btn-cancel ms-auto" onClick={addDnidRow} disabled={isEdit}>
                    + Add DNID
                </button>
            </div>
            {/* DNID Table Section */}
            <div className="mt-0 mx-3 pt-1">
                {dnidInfo?.length ?
                    <>
                        <table className="table pmivr-table header-fixed table-body-block border table-sm mb-0">
                            <thead>
                                <tr>
                                    <th width="20%" className="text-center">DNID
                                        <PmivrTooltip message={TOOLTIP.INFO.DNID}>
                                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                        </PmivrTooltip>
                                    </th>
                                    <th width="25%" className="text-center ps-3">LEXT
                                        <PmivrTooltip message={TOOLTIP.INFO.LEXT}>
                                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                        </PmivrTooltip>
                                    </th>
                                    <th width="20%" className="text-center" title="Transfer Call to CSR (code)">Transfer Call to CSR (code)
                                        <PmivrTooltip message={TOOLTIP.INFO.TRANSFER_CODE}>
                                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                        </PmivrTooltip>
                                    </th>
                                    <th width="25%" className="text-center">Transfer Number
                                        <PmivrTooltip message={TOOLTIP.INFO.TRANSFER_NUMBER}>
                                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                        </PmivrTooltip>
                                    </th>
                                    <th width="10%" className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="dnid-info-box">
                                {dnidInfo?.map((row, index) => {
                                    return (
                                        <tr key={index} className="align-items-end dnid-variable-heading"
                                            // Attach newRowRef to the last row so we can scroll to it when a new row is added.
                                            ref={index === dnidInfo.length - 1 ? newRowRef : null}>
                                            <td className="dnid-variables text-center" width="20%">
                                                {uiState.clientFlowExists && index <= uiState.savedDnidLength - 1 ?
                                                    <span disabled={isEdit ||
                                                        (uiState?.clientFlowExists && index <= uiState.savedDnidLength - 1)}
                                                        className="dnid-variables" type="text">{row.dnid}</span>
                                                    :
                                                    <select className="pmivr-select" value={row.dnid}
                                                        onChange={(e) => { handleSelectDnid(e.target.value, index) }}
                                                        disabled={uiState.showWarning || isEdit}>
                                                        <option value={""}>Select</option>
                                                        {numberPool.map((numberInfo) => (
                                                            <option key={numberInfo.phoneNumber} value={numberInfo.phoneNumber}>
                                                                {numberInfo.phoneNumber}
                                                            </option>
                                                        ))}
                                                    </select>
                                                }
                                            </td>
                                            <td className="dnid-variables text-center" width="25%">
                                                {row.lext ?
                                                    <span disabled={true}>{row.lext}</span> :
                                                    (row.showExt) ?
                                                        <h6 className="error-msg">{MESSAGES.ERR.CONFIGURE_EXT}</h6>
                                                        : "-"
                                                }
                                            </td>
                                            <td className="dnid-variables text-center" width="20%">
                                                {row?.transferChannelCode ?
                                                    <span disabled={true}>{row.transferChannelCode}</span> : "-"
                                                }
                                            </td>
                                            <td className="dnid-variables text-center" width="25%">
                                                {WizardService.getLanguagesFromWizard(questionnaire).map((lang) => {
                                                    return (
                                                        <span disabled={true} className="me-2">
                                                            <span className="me-1">{lang?.toUpperCase()}:</span>
                                                            <span>
                                                                {typeof row?.transferNumber === 'object'
                                                                    ? row?.transferNumber[lang] || "-"
                                                                    : (row?.transferNumber || "-")}
                                                            </span>
                                                        </span>
                                                    )
                                                })}
                                            </td>
                                            <td width="10%" className="text-center">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className='pe-2 cursor-pointer'>
                                                        <button className="pmivr-btn-transparent pmivr-btn-icon mx-auto"
                                                            onClick={() => handleDialogOpen(row, index)}
                                                            disabled={isEdit} >
                                                            <PmivrTooltip message="Edit values">
                                                                <i className="bi bi-pencil-square fa-lg icon-font-size play-button"></i>
                                                            </PmivrTooltip>
                                                        </button>
                                                    </span>
                                                    <span className="pe-2 cursor-pointer">
                                                        <button type="button"
                                                            className="pmivr-btn-transparent pmivr-btn-icon mx-auto"
                                                            onClick={() => handleRemoveDnid(index)}
                                                            title="Remove DNID"
                                                            disabled={(index > uiState.savedDnidLength - 1) ? false : true}>
                                                            <i className="bi bi-trash fa-lg icon-font-size play-button"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </> : MESSAGES.ERR.NO_DNID}
            </div>
        </>
    )
};
// PropTypes for type-checking
ConfigureDnid.propTypes = {
    // It will update the required state in parent component(wizard.js) based on flag
    onUpdate: PropTypes.func,
    // List of DNID rows contains the all the information about DNID (lext, transfer number, variables ect)
    dnidInfo: PropTypes.array,
    // Helps to restore previous state when use click on the cancel button before saveing the changes
    isEdit: PropTypes.bool,
    // Helping to set the different transfer number for different language while configuring the DNID
    questionnaire: PropTypes.arrayOf(
        PropTypes.shape({
            children: PropTypes.arrayOf(PropTypes.shape({})),
            options: PropTypes.arrayOf(PropTypes.shape({})),
            validation: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })),
            flowTypeId: PropTypes.arrayOf(PropTypes.string).isRequired,
            title: PropTypes.string.isRequired,
            questionId: PropTypes.string.isRequired,
            category: PropTypes.string,
            fieldType: PropTypes.string,
            _id: PropTypes.string.isRequired,
        })
    )
};

export default ConfigureDnid;