import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import { useDebouncedEffect } from '../../../hooks/use-debounced-effect.hook';

import AudioService from '../../../services/audio.service';

/**
 * Component for voice files select drop down
 * @param {onSelect, selectedItem, selectedLanguage} props props data from parent component
 * @returns {React.Component} Html code to render voice file select drop down
 */
const VoiceFilesSelect = ({ onSelect, selectedItem, selectedLanguage = '' }) => {
  // voice files drop down list
  const [voiceFileList, setVoiceFileList] = useState([]);
  // State to store the search input for filtering voice files, allowing dynamic updates based on user input.
  const [searchVoiceFile, setSearchVoiceFile] = useState("");

  /**
   * Fetches the list of available voice files based on the selected language and search text.
   * Calls the AudioService to retrieve the voice file list and updates the state.
   * @param {string} searchText - The search query used to filter voice files.
   */
  const getVoiceFileList = async (searchText) => {
    const voiceFilesList = await AudioService.getVoiceFileList(selectedLanguage, searchText);
    setVoiceFileList(voiceFilesList);
  }

  // Define a useDebouncedEffect hook that will delay the API call by 0.5 seconds, to avoid api call for every single letter typed
  // API call will be sent after 1 seconds types any letter, i.e. user can enter text continuously to avoid API call for single letters
  // It helps reduce unnecessary API requests for every keystroke, improving performance and reducing server load.
  useDebouncedEffect(() => {
    getVoiceFileList(searchVoiceFile);
  }, [searchVoiceFile], 1000);

  /**
   * Handles the change in selection
   * @param {Array<{fileName:string,filePath:string}>} selected array of selected item
   */
  const handleSelectionChange = (selected) => {
    // selectedVariable holds an array of user selections.
    // Only first element selected as multiple flag is false.
    const selectedVariable = selected.length ? selected[0] : '';
    if (selectedVariable) {
      onSelect(selectedVariable.filePath);
    }
  };

  return (
    <Typeahead
      id="basic-typeahead-single"
      labelKey="fileName"
      onChange={(selected) => handleSelectionChange(selected)}
      options={voiceFileList}
      onInputChange={(text, _event) => {
        if (text) {
          // Updates the search text state for voice files and triggers 
          // the debounced function to fetch results.
          setSearchVoiceFile(text);
        } else {
          setVoiceFileList([]);
        }
        onSelect(text);
      }}
      placeholder="Enter Voice File Name"
      className="pmivr-variable-input"
      multiple={false}
      selected={selectedItem ? [selectedItem] : []}
    />
  );
};

VoiceFilesSelect.propTypes = {
  // Function to update the selected voice file path
  onSelect: PropTypes.func,
  // Currently selected voice file path
  selectedItem: PropTypes.string,
  // selected language
  selectedLanguage: PropTypes.string,
};

export default VoiceFilesSelect;
