import { envConfig } from "../environment";
import { WIZARD_QUESTION_ID } from "../constants/wizard-questions-id";

import appAxios from "../util/app.axios";

/**
 * service class for wizard operations
 */
class WizardService {

    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}/systemConfig`;

    /**
     * Getting the wizard questions
     * @param {String} flowTypeId selected flow type id
     * @returns {Promise<Array<
     *  {questionId: string, elementId: string, title: string, fieldType: string, options: array, 
    *  childRenderOption: array, children: array, flowTypeId: array, default: array}
     * >>} response from the /systemConfig/wizardConfigQuestions api
     */
    static getWizard(flowTypeId) {
        const url = `${this.BASE_URL}/wizardConfigQuestions?flowTypeId=${flowTypeId}`;
        return appAxios.get(url);
    }

    /**
     * Categorizes questions into different types based on their category.
     *
     * @param {Array} questions - List of questions to be categorized.
     * @returns {Object} An object containing categorized questions.
     */
    static segregateQuestions(questions) {
        if (!questions) return { payments: [], transfer: [], basic: [] };

        return {
            payments: questions.filter(q => q.category === "payments"),
            transfer: questions.filter(q => q.category === "transfer"),
            basic: questions.filter(q => !["payments", "transfer"].includes(q.category))
        };
    }

    /**
     * Get the languages from wizard, since user can update the langauge if on wizard page
     * @param {Array} config list of questions
     */
    static getLanguagesFromWizard = (config) => {
        const languageQuestion = config.find(item => item.questionId === WIZARD_QUESTION_ID.SUPPORTED_LANGUAGES);
        return languageQuestion ? languageQuestion?.value : [];
    }
}

export default WizardService;